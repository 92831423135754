import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import axios, { AxiosResponse } from "axios";
import React, { useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import styled from "styled-components";
import Card from "../../../common/Card/Card";
import PrimaryButton from "../../../common/PrimaryButton/PrimaryButton";
import { configs, StyledComponents } from "../../../constants";
import { COLORS } from "../../../constants/theme";
import { upsertCategoriesTemplateUpdate } from "../../../repositories/categoriesTemplate";
import { CategoryType } from "../../../constants/payloads";
import { useAuth } from "../../../contexts";

function GroupIntoBuckets({
  drawer,
  showDrawer,
  onSuccess = async () => {},
}: any) {
  const [selectedOptionsCashIn, setSelectedOptionsCashIn] =
    React.useState<any>(null);
  const [selectedOptionsCashOut, setSelectedOptionsCashOut] =
    React.useState<any>(null);
  const [bucketsCashIn, setBucketsCashIn] = React.useState<any>([]);
  const [bucketsCashOut, setBucketsCashOut] = React.useState<any>([]);

  const [bucketsLoading, setBucketsLoading] = React.useState<boolean>(false);
  const [savingBuckets, setSavingBuckets] = React.useState<boolean>(false);

  const { selectedScenario, getReportLayout, fetchScenarios } = useAuth();

  useEffect(() => {
    //getReportLayout
    setBucketsLoading(true);
    axios.defaults.withCredentials = true;
    axios
      .get(configs.urls.BASE_URL + "/getReportLayout")
      .then((res) => {
        if (res.data !== "error") {
          const data = res.data;
          if (data.length === 0) {
            bucketsCashIn([]);
          } else {
            let cashIn = data
              .filter((item: any) => item.name === "Receivable")
              .map((item: any) => ({
                label: item.value,
                value: item.value,
              }));
            setSelectedOptionsCashIn(cashIn);
            setBucketsCashIn(cashIn);

            let cashOut = data
              .filter((item: any) => item.name === "Payable")
              .map((item: any) => ({
                label: item.value,
                value: item.value,
              }));
            setSelectedOptionsCashOut(cashOut);
            setBucketsCashOut(cashOut);
          }
        }
      })
      .finally(() => {
        setBucketsLoading(false);
      });
  }, []);

  const onSave = async () => {
    // alert(
    //   JSON.stringify([[...selectedOptionsCashIn], [...selectedOptionsCashOut]])
    // );
    setSavingBuckets(true);

    const { success, data } = await upsertCategoriesTemplateUpdate({
      templateName: "Default Template",
      categories: [
        ...selectedOptionsCashIn.map((item: any) => ({
          name: item.value,
          type: CategoryType.Receivable,
        })),
        ...selectedOptionsCashOut.map((item: any) => ({
          name: item.value,
          type: CategoryType.Payable,
        })),
      ],
      scenarioId: selectedScenario.id,
    });

    if (success) {
      showNotification({
        color: "green",
        message: "Saved!",
        icon: <FontAwesomeIcon icon={faCheck} />,
        autoClose: 2000,
      });
      getReportLayout();
      fetchScenarios();
      await onSuccess();
    } else {
      showNotification({
        color: "red",
        message: "Error saving settings!",
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    }
    setSavingBuckets(false);
    showDrawer(false);
  };

  const MultiValueRemove = (props: any) => {
    return props.data.value === "Receivable" ||
      props.data.value === "Payable" ? (
      <div />
    ) : (
      <components.MultiValueRemove {...props} />
    );
  };

  return (
    <Drawer
      zIndex={999}
      className="custom-scrollbar"
      lockScroll
      styles={{ header: { display: "none" }, drawer: { overflow: "auto" } }}
      position="bottom"
      opened={drawer}
      onClose={() => showDrawer(false)}
      size="full"
    >
      <StyledComponents.DrawerContainer>
        <StyledComponents.DrawerHeader>
          <StyledComponents.DrawerButtonContainer />
          <StyledComponents.DrawerModalTitle className="mt-5">
            Group records in your cashflow into categories
          </StyledComponents.DrawerModalTitle>
          <StyledComponents.DrawerButtonContainer
            variant="default"
            onClick={() => showDrawer(false)}
            icon={
              <FontAwesomeIcon
                icon={faTimes}
                style={{ width: 30, height: 30, color: COLORS.veryLightPink }}
              />
            }
          />
        </StyledComponents.DrawerHeader>

        <StyledComponents.DrawerContent>
          <Container>
            <div
              className="is-flex is-flex-direction-row is-justify-content-space-around "
              style={{ width: "100%" }}
            >
              <Card
                style={{
                  minWidth: "40%",
                  maxWidth: "40%",
                  padding: 25,
                  marginTop: 30,
                  position: "relative",
                  height: "70vh",
                }}
                isNormal
                noShadow
              >
                <CardTitle>Cash-In Categories</CardTitle>

                <SelectFieldLabel>Create categories</SelectFieldLabel>

                <CreatableSelect
                  backspaceRemovesValue={false}
                  isLoading={bucketsLoading}
                  className="select-field"
                  components={{ MultiValueRemove }}
                  value={selectedOptionsCashIn}
                  isClearable={false}
                  isMulti={true}
                  onChange={setSelectedOptionsCashIn}
                  options={bucketsCashIn}
                  styles={{
                    multiValue: (provided) => ({
                      ...provided,
                      border: "1px solid black",
                      borderRadius: 5,
                      boxShadow: "none",
                      backgroundColor: "white",
                      "&:hover": {
                        border: "1px solid #999",
                      },
                    }),
                  }}
                  onCreateOption={(inputValue) => {
                    const newOption = {
                      label: inputValue,
                      value: inputValue,
                    };
                    setBucketsCashIn([...bucketsCashIn, newOption]);
                    setSelectedOptionsCashIn([
                      ...selectedOptionsCashIn,
                      newOption,
                    ]);
                  }}
                />

                <ExamplesContainer>
                  <ExampleTitle>Examples</ExampleTitle>
                  <Examples>
                    Customer payments, investment, financing, etc..
                  </Examples>
                </ExamplesContainer>
              </Card>
              <Card
                style={{
                  minWidth: "40%",
                  maxWidth: "40%",
                  padding: 25,
                  marginTop: 30,
                  height: "70vh",
                  position: "relative",
                }}
                isNormal
                noShadow
              >
                <CardTitle>Cash-Out Categories</CardTitle>
                <SelectFieldLabel>Create categories</SelectFieldLabel>

                <CreatableSelect
                  backspaceRemovesValue={false}
                  isLoading={bucketsLoading}
                  className="select-field"
                  value={selectedOptionsCashOut}
                  components={{ MultiValueRemove }}
                  isClearable={false}
                  isMulti={true}
                  onChange={setSelectedOptionsCashOut}
                  options={bucketsCashOut}
                  styles={{
                    multiValue: (provided) => ({
                      ...provided,
                      border: "1px solid black",
                      borderRadius: 5,
                      boxShadow: "none",
                      backgroundColor: "white",
                      "&:hover": {
                        border: "1px solid #999",
                      },
                    }),
                  }}
                  onCreateOption={(inputValue) => {
                    const newOption = {
                      label: inputValue,
                      value: inputValue,
                    };
                    setBucketsCashOut([...bucketsCashOut, newOption]);
                    setSelectedOptionsCashOut([
                      ...selectedOptionsCashOut,
                      newOption,
                    ]);
                  }}
                />

                <ExamplesContainer>
                  <ExampleTitle>Examples</ExampleTitle>
                  <Examples>
                    Salaries, vendors, consultants, loan payments, transport
                    cost, insurance, cloud services, etc…
                  </Examples>
                </ExamplesContainer>
              </Card>
            </div>

            <PrimaryButton
              style={{ width: 400 }}
              onClick={onSave}
              className={`mt-4 ${savingBuckets ? "is-loading" : ""}`}
            >
              Save
            </PrimaryButton>
          </Container>
        </StyledComponents.DrawerContent>
      </StyledComponents.DrawerContainer>
    </Drawer>
  );
}

export default GroupIntoBuckets;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
  @media screen and (max-width: 1600px) {
    padding-bottom: 0px;
  }
`;
const CardTitle = styled.h4`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;

  @media screen and (max-width: 1600px) {
    font-size: 20px;
  }
`;

const ExamplesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 25px;
  position: absolute;
  bottom: 0;
  height: 60px;

  @media screen and (max-width: 1600px) {
    bottom: 20px;
  }
`;

const ExampleTitle = styled.h4`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  font-size: 14px;
  color: ${COLORS.brownishGrey};
`;

const Examples = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  margin: 15px 0 0 1px;
  color: ${COLORS.brownGrey};
`;

const SelectFieldLabel = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: ${COLORS.brownishGrey};
  margin-top: 20px;
`;
