import "../../styles.css";
import {
  faCheck,
  faPaperclip,
  faPlus,
  faTimes,
  faEdit,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Drawer, Tooltip } from "@mantine/core";
import { showNotification, updateNotification } from "@mantine/notifications";
import _ from "lodash";
import moment from "moment";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import CustomTabs from "../../../../common/PageLayout/components/CustomTabs";
import List from "../../../../common/PageLayout/components/List";
import ListContainer from "../../../../common/PageLayout/components/ListContainer";
import ListHeader from "../../../../common/PageLayout/components/ListHeader";
import { IMAGES } from "../../../../constants";
import { IS_LEAN_SANDBOX, LEAN_APP_TOKEN } from "../../../../constants/configs";
import {
  MENU,
  PaymentStatus,
  sortDropdown,
  filterDropdown,
  SORT_BY,
  TAKE,
  sortDropdownEstimates,
} from "../../../../constants/globalConstants";
import { COLORS } from "../../../../constants/theme";
import { useAuth } from "../../../../contexts";
import { formatCurrency } from "../../../../modules";
import { cutLengthyString } from "../../../../modules/cutLengthyString";
import { getLatestTransaction } from "../../../../modules/getLatestTransaction";
import { usePaymentsContext } from "../../contexts/PaymentsContext";
import { authorizePayments, rejectPayments } from "../../repository";
import {
  getSearchTextPayLS,
  setSearchTextPayLS,
  removeSearchTextPayLS,
  getSortByPayLS,
  setSortByPayLS,
  removeSortByPayLS,
  setFilterByPayLS,
  removeFilterByPayLS,
  getFilterByPayLS,
  removeContactsFilterPayLS,
  setContactsFilterPayLS,
  getContactsFilterPayLS,
  getBucketsFilterPayLS,
  setBucketsFilterPayLS,
  removeBucketsFilterPayLS,
} from "../../../../modules/localStorage";
import {
  deleteAllForecasts,
  deleteAllPayables,
  deleteForecasts,
  deleteInvoiceByKeys,
  getAllPayablesContactNames,
  getBuckets,
} from "../../../../repositories/invoiceRepository";
import { DateRangePicker, DateRangePickerValue } from "@mantine/dates";
import { filterByDueDate, filterRecords, sortRecords } from "../common";
import { CustomModal } from "../../../../common/CustomModal/CustomModal";
import { renderFilterIconButton } from "../../../../common/IconButton/FilterIconButton";
import { renderSortIconButton } from "../../../../common/IconButton/IconButton";
import { searchFilter } from "../common/searchFilter";
import { getHighlightedText } from "../../../../modules/getHighlightedText";
import DropdownComponents from "../common/DropdownComponents";
import { IconButton, Popover, Whisper } from "rsuite";
import {
  deleteBlackIcon,
  deleteIcon,
  searchBlackIcon,
  searchIcon,
} from "../../../../constants/images";
import { getFilterObj } from "../common/getFilterObject";
import AddActionPopover from "../../../../common/AddActionPopover";
import AddEditForecastBudgetModal from "../../../Cashflow/components/ForecastBudget/AddEditForecastBudgetModal";
import EditBillsOrInvoices from "../common/Edit/EditBillsOrInvoices";
import UploadInvoiceInBulk from "../common/Upload/UploadInvoiceInBulk";
import EditEstimatesInBulk from "../common/Edit/EditEstimatesInBulk";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function LeftView({ isViewOnly }: any) {
  const {
    setGlobalLoading,
    totalRecords,
    records: _records,
    getRecords,
    recordsLoading,
    fetchCashflowReport,
    forecasts,
    fetchForecasts,
    selectedScenario,
  } = useAuth();

  const {
    setPaymentType,
    showDrawer,
    customerId,
    endUserId,
    leanCallback,
    searchParams,
    setSearchParams,
  } = usePaymentsContext();

  const [isEstimates, setIsEstimates] = useState(false);

  const query = useQuery();

  useEffect(() => {
    const tab = query.get("tab");
    if (tab === "estimates") {
      setIsEstimates(true);
    } else {
      setIsEstimates(false);
    }
  }, [query]);

  const [activeTab, setActiveTab] = useState<string>(
    searchParams.get("tab") || "all"
  );
  const [skip, setSkip] = useState<number>(0);
  const [totalForecasts, setTotalForecasts] = useState<number>(0);
  const [records, setRecords] = useState<Record<string, any>[]>([]);
  const [searchText, setSearchText] = useState<string>(
    getSearchTextPayLS() || ""
  );
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [backendValue, setBackendValue] = useState<string>("");
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [filterDropdownList, setfilterDropdownList] = useState(filterDropdown);
  const sortBy = getSortByPayLS() ? getSortByPayLS().split("/") : ["", ""];
  const filterBy = getFilterByPayLS()
    ? getFilterByPayLS().split("/")
    : ["", ""];
  const [contacts, setContacts] = useState<string[]>(getContactsFilterPayLS());
  const [buckets, setBuckets] = useState<string[]>(getBucketsFilterPayLS());
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isSortSelected, setIsSortSelected] = useState(
    getSortByPayLS() ? true : false
  );

  const [isFilterSelected, setIsfilterSelected] = useState(
    getFilterByPayLS() ? true : false
  );

  const [datePickerValue, setDatePickerValue] = useState<DateRangePickerValue>([
    null,
    null,
  ]);

  const [sortMenu, setSortMenu] = useState({
    mainmenu: sortBy[0],
    submenu: sortBy[1],
  });
  const [filterMenu, setfilterMenu] = useState({
    mainmenu: filterBy[0],
    submenu: filterBy[1],
  });

  const [openSearch, setOpenSearch] = useState<Boolean>(
    getSearchTextPayLS() ? true : false
  );

  const [editInBulk, setEditInBulk] = useState<boolean>(false);
  const [editRecordState, setEditRecordState] = useState<Record<string, any>[]>(
    []
  );

  const [uploadInBulk, setUploadInBulk] = useState<boolean>(false);

  const isForecastBudgetTab = () =>
    activeTab === "estimates" || activeTab === "budgets";

  const isForecastTab = () => activeTab === "estimates";
  const isBudgetTab = () => activeTab === "budgets";

  const whichRecordsFilter = () => {
    if (activeTab === "all") {
      return records;
    } else if (activeTab === "paid") {
      return records.filter(
        (record: any) => record.status === PaymentStatus.Paid
      );
    } else if (activeTab === "unpaid") {
      return records.filter(
        (record: any) =>
          record.status === PaymentStatus.Unpaid ||
          record.status === PaymentStatus.PartiallyPaid
      );
    } else if (activeTab === "queued") {
      return records.filter(
        (record: any) => record.status === PaymentStatus.PendingAuthorization
      );
    } else if (activeTab === "estimates") {
      return forecasts.filter(
        (forecast: any) => forecast.settings.type === "Payable"
      );
    } else {
      return records;
    }
  };

  useEffect(() => {
    // let itemId = parseInt(
    //   searchParams.get("selectedBillId")
    //     ? searchParams.get("selectedBillId")
    //     : recordsToDisplay?.length
    //     ? recordsToDisplay[0].id
    //     : ""
    // );
    // window.localStorage.setItem("selectedBillId", itemId.toString());

    setIsSortSelected(false);
    setIsfilterSelected(false);
    setSortMenu({ mainmenu: "", submenu: "" });
    setfilterMenu({ mainmenu: "", submenu: "" });
    // setContacts([]);
    // setBuckets([]);
    // setSearchText("");
    // setOpenSearch(false);
    // removeSortByPayLS();
    // removeFilterByPayLS();
    // removeContactsFilterPayLS();
    // removeBucketsFilterPayLS();
    // removeSearchTextPayLS();
  }, []);

  useEffect(() => {
    setRecords(_records);
  }, [_records]);

  useEffect(() => {
    const sortOrder =
      sortMenu.submenu === (MENU.ASCENDING || MENU.NEWEST_TO_OLDEST)
        ? SORT_BY.ASC
        : SORT_BY.DESC;
    let filterObj = getFilterObj(
      filterMenu,
      activeTab,
      datePickerValue,
      searchText,
      contacts,
      buckets
    );

    if (openSearch && !searchText) {
      if (isForecastTab()) {
        fetchForecasts(filterObj, sortOrder, backendValue);
      } else {
        getRecords(undefined, 0, "Payable", backendValue, sortOrder, filterObj);
      }

      setSkip(0);
      return;
    }

    const delayDebounceFn = setTimeout(async () => {
      setSkip(0);
      getRecords(undefined, 0, "Payable", backendValue, sortOrder, filterObj);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  useEffect(() => {
    (async () => {
      const sortOrder =
        sortMenu.submenu === (MENU.ASCENDING || MENU.NEWEST_TO_OLDEST)
          ? SORT_BY.ASC
          : SORT_BY.DESC;

      let filterObj = getFilterObj(
        filterMenu,
        activeTab,
        datePickerValue,
        searchText,
        contacts,
        buckets
      );

      if (isForecastTab()) {
        fetchForecasts(filterObj, sortOrder, backendValue);
      } else {
        getRecords(undefined, 0, "Payable", backendValue, sortOrder, filterObj);
      }
      setSkip(0);
      await new Promise((resolve) => setTimeout(resolve, 300));
    })();
  }, [activeTab, sortMenu.mainmenu, sortMenu.submenu]);

  useEffect(() => {
    (async () => {
      if (
        !filterMenu.mainmenu ||
        !filterMenu.submenu ||
        filterMenu.submenu === MENU.DATE_RANGE
      ) {
        return;
      }
      const sortOrder =
        sortMenu.submenu === (MENU.ASCENDING || MENU.NEWEST_TO_OLDEST)
          ? SORT_BY.ASC
          : SORT_BY.DESC;

      let filterObj = getFilterObj(
        filterMenu,
        activeTab,
        datePickerValue,
        searchText,
        contacts,
        buckets
      );

      if (isForecastTab()) {
        fetchForecasts(filterObj, sortOrder, backendValue);
      } else {
        getRecords(undefined, 0, "Payable", backendValue, sortOrder, filterObj);
      }
      setSkip(0);
      await new Promise((resolve) => setTimeout(resolve, 300));
    })();
  }, [filterMenu.mainmenu, filterMenu.submenu]);

  useEffect(() => {
    (async () => {
      let result: any[] = [];
      const records = whichRecordsFilter();
      if (isForecastBudgetTab()) {
        result = records.map(({ name }: any) => ({
          value: `${MENU.BY_CONTACT}/${name}`,
          label: name,
        }));
      } else {
        result = records.map(({ contact }: any) => ({
          value: `${MENU.BY_CONTACT}/${contact?.displayName}`,
          label: contact?.displayName,
        }));
      }
      const uniqueResults: any = _.uniqBy(result, "label");
      filterDropdownList[1].children = uniqueResults;
      filterDropdownList[2].children = await getBuckets({
        type: "Payable",
      });
      setfilterDropdownList(filterDropdownList);
    })();
  }, [selectedScenario, records, forecasts]);

  let isQueuedTab: boolean = activeTab === "queued";

  let recordsToDisplay: any = useMemo(() => {
    if (isForecastBudgetTab()) {
      if (isForecastTab()) {
        let filteredRecords = forecasts.filter(
          (forecast: any) => forecast.settings.type === "Payable"
        );

        setTotalForecasts(filteredRecords.length);

        return filteredRecords;
      } else return [];
    } else {
      let filteredRecords = whichRecordsFilter();
      if (!filteredRecords?.length) {
        return;
      }

      // if (!showOlderRecords) {
      //   filteredRecords = filteredRecords.filter((record: any) =>
      //     moment(record.dueDate).isSameOrAfter(moment(new Date()), "day")
      //   );
      // }
      const beforeFilter = _.cloneDeep(filteredRecords);
      if (searchText) {
        filteredRecords = searchFilter(searchText, filteredRecords);
      }
      if (!openSearch) {
        filteredRecords = _.cloneDeep(beforeFilter);
      }
      if (filterMenu.mainmenu && isFilterSelected) {
        if (filterMenu.submenu !== MENU.DATE_RANGE) {
          filteredRecords = filterRecords(
            filterMenu.submenu,
            filteredRecords,
            contacts
          );
        }
        if (filterMenu.submenu === MENU.DATE_RANGE && !isModalOpen) {
          filteredRecords = filterByDueDate(
            filteredRecords,
            moment(datePickerValue[0]).toISOString(),
            moment(datePickerValue[1]).toISOString()
          );
        }
      }

      if (sortMenu.mainmenu) {
        if (
          isSortSelected &&
          (sortMenu.mainmenu === MENU.BY_DATE ||
            sortMenu.mainmenu === MENU.BY_ISSUE_DATE ||
            MENU.BY_INVOICE ||
            MENU.BY_INVOICE)
        ) {
          filteredRecords = sortRecords(
            sortMenu.mainmenu,
            sortMenu.submenu,
            filteredRecords
          );
        }
      } else {
        filteredRecords = sortRecords(
          MENU.BY_DATE,
          MENU.NEWEST_TO_OLDEST,
          filteredRecords
        );
      }

      return _.cloneDeep(filteredRecords);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeTab,
    records,
    searchText,
    openSearch,
    sortMenu.submenu,
    sortMenu.mainmenu,
    filterMenu.mainmenu,
    filterMenu.submenu,
    isSortSelected,
    isFilterSelected,
    // isModalOpen,
    contacts.length,
    forecasts,
  ]);
  const deleteRecords = async () => {
    if (!selectedKeys.length) {
      showNotification({
        color: "red",
        message: "Please select a record or more to delete",
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
      return;
    }
    if (!window.confirm("Warning: Are you sure to delete?")) {
      return;
    }
    showNotification({
      id: "delete-data",
      loading: true,
      message: "Please wait while deletion is in process",
      autoClose: false,
      disallowClose: true,
    });
    // axios call

    try {
      let result: any = [];

      if (
        allSelected &&
        selectedKeys.length === _records.length &&
        !openSearch &&
        !isSortSelected &&
        !isFilterSelected
      ) {
        if (isForecastBudgetTab()) {
          result = await deleteAllForecasts();
        } else {
          result = await deleteAllPayables();
        }
      } else {
        if (isForecastBudgetTab()) {
          result = await deleteForecasts(selectedKeys);
        } else {
          result = await deleteInvoiceByKeys(selectedKeys);
        }
      }
      if (result.data.success) {
        if (isForecastBudgetTab()) {
          fetchForecasts();
        } else {
          getRecords();
        }
        updateNotification({
          id: "delete-data",
          color: "teal",
          message:
            (allSelected && selectedKeys === _records.length
              ? totalRecords?.totalPayable
              : selectedKeys.length) + " item(s) deleted!",
          icon: <FontAwesomeIcon icon={faCheck} />,
          autoClose: 5000,
        });
      } else {
        updateNotification({
          id: "delete-data",
          color: "red",
          message: `Cannot delete record(s)!`,
          icon: <FontAwesomeIcon icon={faTimes} />,
          autoClose: 2000,
        });
      }
    } catch (e) {
      console.log({ error: e });
      updateNotification({
        id: "delete-data",
        color: "red",
        message: `Cannot delete record(s)!`,
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    }
  };

  const editRecords = () => {
    if (!selectedKeys.length) {
      showNotification({
        color: "red",
        message: "Please select a record or more to edit",
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
      return;
    }
    // showNotification({
    //   id: "edit-data",
    //   loading: true,
    //   message: "Please wait while editing is in process",
    //   autoClose: false,
    //   disallowClose: true,
    // });
    // axios call

    try {
      // console.log("selected keys: ", selectedKeys);
      const selectedKeysAsIntegers = selectedKeys.map((key) => parseInt(key));
      const selectedRecords = recordsToDisplay.filter((record: any) =>
        selectedKeysAsIntegers.includes(record.id)
      );
      setEditInBulk(true);
      setEditRecordState(selectedRecords);
      // console.log("selected records ... ", selectedRecords);
    } catch (error) {
      console.log("Error inside editAllForecasts");
    }
  };

  const renderCardChildren = (bill: any) =>
    isForecastBudgetTab() ? (
      <div style={{ position: "relative" }}>
        {/* Header */}
        <div className="is-flex is-flex-direction-row is-justify-content-space-between">
          <div className="flex-direction-row">
            <figure className="image" style={{ width: 40, height: 40 }}>
              <img
                className="is-rounded"
                src={IMAGES.company_logo_placeholder}
                alt=""
              />
            </figure>
            <div className="subtitle ml-2 mt-1" style={{ fontWeight: "700" }}>
              {getHighlightedText(cutLengthyString(bill?.name, 25), searchText)}
            </div>
          </div>
          {/* {moment(bill?.settings?.endForecastDate).toISOString() <
            moment().toISOString() && (
            <button className="overdue-status">Expired!</button>
          )} */}
          {moment(bill?.dueDate).toISOString() < moment().toISOString() && (
            <button className="overdue-status">Expired!</button>
          )}
        </div>

        {/* Details */}
        <div className="flex-direction-row-space-between  date-amount-container">
          <div>
            <div className="card-date">
              {getHighlightedText(
                "Due " +
                  moment(bill.dueDate).format("MMM, DD, YYYY").toString() || "",
                searchText
              )}
            </div>
            <div className="card-code">
              {_.isEmpty(bill.repeats) || !bill.repeats.trim() ? (
                <i>No bill number.</i>
              ) : (
                getHighlightedText(`${bill.repeats}`, searchText)
              )}
            </div>
          </div>
          <div className="subtitle ml-4" style={{ fontWeight: "700" }}>
            {bill.currency || "AED"}{" "}
            {isForecastBudgetTab() ? (
              <>{getHighlightedText(bill?.amount, searchText)}</>
            ) : (
              <>
                {" "}
                {getHighlightedText(
                  formatCurrency(parseFloat(bill?.amount || "0"), 2) || "",
                  searchText
                )}{" "}
              </>
            )}
          </div>
        </div>
        {bill.image && (
          <div style={{ position: "absolute", top: 0, right: 0 }}>
            <FontAwesomeIcon icon={faPaperclip} />
          </div>
        )}
      </div>
    ) : (
      <div style={{ position: "relative" }}>
        {/* Header */}
        <div className="is-flex is-flex-direction-row is-justify-content-space-between">
          <div className="flex-direction-row">
            <figure className="image" style={{ width: 40, height: 40 }}>
              <img
                className="is-rounded"
                src={IMAGES.company_logo_placeholder}
                alt=""
              />
            </figure>
            <div className="subtitle ml-2 mt-1" style={{ fontWeight: "700" }}>
              {getHighlightedText(cutLengthyString(bill?.name, 25), searchText)}
            </div>
          </div>

          {bill.status === "Unpaid" &&
            moment(bill.dueDate).toISOString() < moment().toISOString() && (
              <button className="overdue-status"> Overdue!</button>
            )}

          {bill.status === "PartiallyPaid" && (
            <button className="overdue-status orange">Partially Paid</button>
          )}
          {bill.status === PaymentStatus.PendingAuthorization && (
            <div>
              <Badge>Pending Authorization</Badge>
            </div>
          )}
        </div>

        {/* Details */}
        <div className="flex-direction-row-space-between  date-amount-container">
          <div>
            <div className="card-date">
              {bill.status === PaymentStatus.Paid
                ? getHighlightedText(
                    "Paid on " +
                      moment(
                        getLatestTransaction(bill?.linkedTransactions)
                          ?.paidOrFailedAt
                      ).format("MMM, DD, YYYY"),
                    searchText
                  )
                : getHighlightedText(
                    "Due " +
                      moment(bill.expectedDueDate)
                        .format("MMM, DD, YYYY")
                        .toString() || "",
                    searchText
                  )}
            </div>
            <div className="card-code">
              {_.isEmpty(bill.invoiceNumber) || !bill.invoiceNumber.trim() ? (
                <i>No bill number.</i>
              ) : (
                getHighlightedText(`#${bill.invoiceNumber}`, searchText)
              )}
            </div>
          </div>
          <div className="subtitle ml-4" style={{ fontWeight: "700" }}>
            {bill.currency || "AED"}{" "}
            {getHighlightedText(
              formatCurrency(parseFloat(bill?.amountTotal || "0"), 2) || "",
              searchText
            )}
          </div>
        </div>
        {bill.image && (
          <div style={{ position: "absolute", top: 0, right: 0 }}>
            <FontAwesomeIcon icon={faPaperclip} />
          </div>
        )}
      </div>
    );

  const handleChangeSearch = (e: any) => {
    setSearchTextPayLS(e.target.value);
    setSearchText(e.target.value);
  };

  const handleChangeOpenSearch = () => {
    const sortOrder =
      sortMenu.submenu === (MENU.ASCENDING || MENU.NEWEST_TO_OLDEST)
        ? SORT_BY.ASC
        : SORT_BY.DESC;

    if (openSearch) {
      removeSearchTextPayLS();
      setSearchText("");
      let filterObj = getFilterObj(
        filterMenu,
        activeTab,
        datePickerValue,
        searchText,
        contacts,
        buckets
      );

      if (isForecastTab()) {
        fetchForecasts(filterObj, sortOrder, backendValue);
      } else {
        getRecords(undefined, 0, "Payable", backendValue, sortOrder, filterObj);
      }

      setSkip(0);
    }
    setOpenSearch(!openSearch);
  };

  const handleSelectedKeys = (keys: string[]) => {
    setSelectedKeys(keys);
  };

  const onSortSelect = (item: any, selectedPaths: any, event: any) => {
    const items = item.value.split("/");
    const mainmenu = items[0];
    const submenu = items?.[1] || "";
    setSortMenu({
      mainmenu,
      submenu,
    });
    const sortOrder =
      submenu === (MENU.ASCENDING || MENU.NEWEST_TO_OLDEST)
        ? SORT_BY.ASC
        : SORT_BY.DESC;
    setBackendValue(item.backendValue);

    let filterObj = getFilterObj(
      filterMenu,
      activeTab,
      datePickerValue,
      searchText,
      contacts,
      buckets
    );
    getRecords(undefined, 0, "Payable", backendValue, sortOrder, filterObj);
    setSkip(0);
    setSortByPayLS(`${mainmenu}/${submenu}`);
    setIsSortSelected(true);
  };

  const handleLoadMore = async (skipVal: any) => {
    // fake delay
    const sortOrder =
      sortMenu.submenu === (MENU.ASCENDING || MENU.NEWEST_TO_OLDEST)
        ? SORT_BY.ASC
        : SORT_BY.DESC;

    let filterObj = getFilterObj(
      filterMenu,
      activeTab,
      datePickerValue,
      searchText,
      contacts,
      buckets
    );
    getRecords(
      undefined,
      skip + TAKE,
      "Payable",
      backendValue,
      sortOrder,
      filterObj
    );
    setSkip(skip + TAKE);
    await new Promise((resolve) => setTimeout(resolve, 300));
  };

  const onFilterSelect = (item: any, selectedPaths: any, event: any) => {
    const items = item.value.split("/");
    const mainmenu = items[0];
    const submenu = items[1];
    // alert(submenu);
    setfilterMenu({
      mainmenu,
      submenu,
    });

    if (submenu === MENU.DATE_RANGE) {
      setIsModalOpen(true);
    }
    if (mainmenu === MENU.BY_DATE_FILTER) {
      setContacts([]);
      removeContactsFilterPayLS();
      removeBucketsFilterPayLS();
    }
    if (mainmenu === MENU.BY_CONTACT && submenu) {
      if (contacts.includes(submenu)) {
        const filteredContacts = contacts.filter(
          (contact) => contact !== submenu
        );
        setContacts(filteredContacts);
        setContactsFilterPayLS(filteredContacts.join(","));
      } else {
        setContacts([...contacts, submenu]);
        setContactsFilterPayLS([...contacts, submenu].join(","));
      }
    }
    if (mainmenu === MENU.BY_BUCKET && submenu) {
      if (buckets.includes(submenu)) {
        const filteredBuckets = buckets.filter((bucket) => bucket !== submenu);
        setBuckets(filteredBuckets);
        setBucketsFilterPayLS(filteredBuckets.join(","));
      } else {
        setBuckets([...buckets, submenu]);
        setBucketsFilterPayLS([...buckets, submenu].join(","));
      }
    }

    setFilterByPayLS(`${mainmenu}/${submenu}`);
    setIsfilterSelected(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSubmitDateRange = async () => {
    const sortOrder =
      sortMenu.submenu === (MENU.ASCENDING || MENU.NEWEST_TO_OLDEST)
        ? SORT_BY.ASC
        : SORT_BY.DESC;
    let filterObj = getFilterObj(
      filterMenu,
      activeTab,
      datePickerValue,
      searchText,
      contacts,
      buckets
    );

    if (isForecastTab()) {
      fetchForecasts(filterObj, sortOrder, backendValue);
    } else {
      getRecords(undefined, 0, "Payable", backendValue, sortOrder, filterObj);
    }

    setSkip(0);
    await new Promise((resolve) => setTimeout(resolve, 300));
    handleModalClose();
  };

  const handleSortClick = () => {
    if (isSortSelected) {
      setSortMenu({
        mainmenu: "",
        submenu: "",
      });
      removeSortByPayLS();
    }
    setIsSortSelected(!isSortSelected);
  };

  const handleFilterClick = () => {
    if (isFilterSelected) {
      setfilterMenu({
        mainmenu: "",
        submenu: "",
      });
      setRecords(_records);
      removeFilterByPayLS();
      removeContactsFilterPayLS();
      removeBucketsFilterPayLS();
      getRecords();

      setContacts([]);
    }
    setIsfilterSelected(!isFilterSelected);
  };

  const handleSortMenuClose = () => {
    setSortMenu({
      mainmenu: "",
      submenu: "",
    });

    removeSortByPayLS();
  };

  const handleFilterMenuClose = () => {
    setfilterMenu({
      mainmenu: "",
      submenu: "",
    });
    setContacts([]);
    removeContactsFilterPayLS();
    removeBucketsFilterPayLS();
    removeFilterByPayLS();
  };

  const [drawer, setDrawer] = React.useState(false);
  const [drawerType, setDrawerType] = React.useState<"forecast" | "budget">(
    "forecast"
  );
  const renderPlusButton = () => {
    return (
      <Fragment>
        <Whisper
          placement="bottomStart"
          trigger="click"
          speaker={({ onClose, left, top, className }: any, ref: any) => {
            const handleSelect = (eventKey: any) => {
              switch (eventKey) {
                case 1:
                  break;
                case 2:
                case 3:
                  // setDrawerType("budget");
                  // setDrawer(true);
                  break;
                case 4:
                  setPaymentType("create");
                  showDrawer(true);
                  break;
                case 5:
                case 6:
                  setDrawerType("forecast");
                  setDrawer(true);
                  break;

                default:
                  break;
              }
              onClose();
            };
            return (
              <Popover
                ref={ref}
                className={className}
                style={{ left, top }}
                full
              >
                <AddActionPopover handleSelect={handleSelect} show="cash-out" />
              </Popover>
            );
          }}
        >
          <Button
            disabled={isViewOnly}
            style={{
              backgroundColor: isViewOnly
                ? COLORS.btnDisabled
                : COLORS.greenBlue,
              color: "white",
              padding: "0 13px",
            }}
          >
            <FontAwesomeIcon icon={faPlus} style={{ fontSize: 16 }} />
          </Button>
        </Whisper>
        <AddEditForecastBudgetModal
          opened={drawer}
          onClose={() => {
            setDrawer(false);
          }}
          type={drawerType}
          section="Payable"
        />
      </Fragment>
    );
  };

  return (
    <ListContainer>
      <ListHeader
        hasButton
        buttonTitle="Add a bill"
        buttonAction={() => {
          setPaymentType("create");
          showDrawer(true);
        }}
        title="Pay"
        renderButton={renderPlusButton}
      />
      <List
        isViewOnly={isViewOnly}
        handleChangeSearch={handleChangeSearch}
        handleChangeOpenSearch={handleChangeOpenSearch}
        handleSelectedKeys={handleSelectedKeys}
        openSearch={openSearch}
        setAllSelected={setAllSelected}
        searchText={searchText}
        totalRecords={
          isForecastBudgetTab() ? totalForecasts : totalRecords.totalPayable
        }
        paginate={
          isForecastBudgetTab() ? totalForecasts : totalRecords.totalPayable
        }
        activeTab={activeTab}
        onLoadMore={async (skip: number) => {
          handleLoadMore(skip);
        }}
        renderSubHeader={
          isQueuedTab
            ? (checkedKeys: any) => (
                <div className="is-flex is-justify-content-center buttons are-small pb-2">
                  <button
                    onClick={() => {
                      if (
                        window.confirm(
                          "Are you sure to reject the selected payment(s)?"
                        )
                      ) {
                        setGlobalLoading(true);
                        rejectPayments(checkedKeys, () => {
                          getRecords();
                          setGlobalLoading(false);
                          setSearchParams({ tab: "queued" });
                        });
                      }
                    }}
                    className="button is-danger"
                    style={{
                      color: "white",
                      backgroundColor: COLORS.btnDelete,
                    }}
                  >
                    Reject {checkedKeys.length}
                  </button>
                  <button
                    onClick={() => {}}
                    className="button"
                    style={{
                      color: "white",
                      backgroundColor: COLORS.uglyBlue,
                    }}
                  >
                    Authorize {checkedKeys.length}
                  </button>
                </div>
              )
            : undefined
        }
        noDataMessage={
          isQueuedTab && "Payments queued for authorization will appear here."
        }
        style={{
          height: openSearch ? "calc(100vh - 360px)" : "calc(100vh - 245px)",
          overflowY: "scroll",
        }}
        list={recordsToDisplay}
        renderItem={renderCardChildren}
        listLoading={recordsLoading}
        renderTabs={() => (
          <CustomTabs
            active={activeTab}
            setActive={setActiveTab}
            tabs={[
              { key: "all", title: "All" },
              { key: "unpaid", title: "Upcoming" },
              { key: "paid", title: "Paid" },
              { key: "estimates", title: "Estimates" },
              // { key: "budgets", title: "Budgets" },
              // { key: "queued", title: "Queued" },
            ]}
            list={recordsToDisplay}
            totalRecords={totalRecords.totalPayable}
          />
        )}
        type="pay"
        renderActions={() => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {renderSortIconButton(
              handleSortClick,
              isSortSelected,
              sortMenu.mainmenu,
              isViewOnly
            )}
            {renderFilterIconButton(
              handleFilterClick,
              isFilterSelected,
              filterMenu.mainmenu,
              isViewOnly
            )}

            <CustomModal
              isOpen={isModalOpen}
              handleClose={recordsLoading ? undefined : handleModalClose}
              title={"Choose Date Range"}
              buttonText="Submit"
              handleButtonClick={handleSubmitDateRange}
              buttonPosition="center"
              recordsLoading={recordsLoading}
              disableClose={recordsLoading}
            >
              <DateRangePicker
                label=""
                placeholder="Pick dates range"
                value={datePickerValue}
                onChange={setDatePickerValue}
              />
              <br />
            </CustomModal>
            <Tooltip
              label="Upload records"
              transition="scale-y"
              transitionDuration={300}
            >
              <div style={{ display: "flex", margin: "0 1px" }}>
                <IconButton
                  disabled={isViewOnly}
                  size="lg"
                  className="sort-icon-button"
                  onClick={() => {
                    setUploadInBulk(true);
                  }}
                  icon={
                    <FontAwesomeIcon
                      icon={faUpload}
                      style={{ fontSize: 21, color: "#717171" }}
                    />
                  }
                />
                {isEstimates ? (
                  <AddEditForecastBudgetModal
                    opened={uploadInBulk}
                    onClose={() => setUploadInBulk(false)}
                    type="forecast"
                    isDetailsOnly={false}
                    data={[]}
                    section="Payable"
                  />
                ) : (
                  <UploadInvoiceInBulk
                    getRecords={getRecords}
                    uploadInBulk={uploadInBulk}
                    setUploadInBulk={setUploadInBulk}
                    title={"Upload Bills In Bulk"}
                    recordType="Bill"
                    contactType="Vendor"
                    cashflowType="Payable"
                    isBill={true}
                  />
                )}
              </div>
            </Tooltip>
            {/* {!isEstimates && (
              <Tooltip
                label="Edit bills"
                transition="scale-y"
                transitionDuration={300}
              >
                <div style={{ display: "flex", margin: "0 1px" }}>
                  <IconButton
                    disabled={isViewOnly}
                    size="lg"
                    className="sort-icon-button"
                    onClick={editRecords}
                    icon={
                      <FontAwesomeIcon
                        icon={faEdit}
                        style={{ fontSize: 21, color: "#717171" }}
                      />
                    }
                  />
                  <EditBillsOrInvoices
                    getRecords={getRecords}
                    setRecords={setRecords}
                    editInBulk={editInBulk}
                    setEditInBulk={setEditInBulk}
                    records={editRecordState}
                    setEditRecordState={setEditRecordState}
                    title={"Edit Bills In Bulk"}
                    isBill={true}
                    deleteRecords={deleteRecords}
                  />
                </div>
              </Tooltip>
            )} */}

            {isEstimates ? (
              <Tooltip
                label="Edit estimates"
                transition="scale-y"
                transitionDuration={300}
              >
                <div style={{ display: "flex", margin: "0 1px" }}>
                  <IconButton
                    disabled={isViewOnly}
                    size="lg"
                    className="sort-icon-button"
                    onClick={editRecords}
                    icon={
                      <FontAwesomeIcon
                        icon={faEdit}
                        style={{ fontSize: 21, color: "#717171" }}
                      />
                    }
                  />
                  <EditEstimatesInBulk
                    selectedKeys={selectedKeys}
                    fetchForecasts={fetchForecasts}
                    setRecords={setRecords}
                    editInBulk={editInBulk}
                    setEditInBulk={setEditInBulk}
                    records={editRecordState}
                    setEditRecordState={setEditRecordState}
                    title={"Edit Estimate Details In Bulk"}
                    deleteRecords={deleteRecords}
                    cashflowType="Payable"
                  />
                </div>
              </Tooltip>
            ) : (
              <Tooltip
                label="Edit bills"
                transition="scale-y"
                transitionDuration={300}
              >
                <div style={{ display: "flex", margin: "0 1px" }}>
                  <IconButton
                    disabled={isViewOnly}
                    size="lg"
                    className="sort-icon-button"
                    onClick={editRecords}
                    icon={
                      <FontAwesomeIcon
                        icon={faEdit}
                        style={{ fontSize: 21, color: "#717171" }}
                      />
                    }
                  />
                  <EditBillsOrInvoices
                    getRecords={getRecords}
                    setRecords={setRecords}
                    editInBulk={editInBulk}
                    setEditInBulk={setEditInBulk}
                    records={editRecordState}
                    setEditRecordState={setEditRecordState}
                    title={"Edit Bills In Bulk"}
                    isBill={true}
                    deleteRecords={deleteRecords}
                  />
                </div>
              </Tooltip>
            )}

            <Tooltip
              label="Delete records"
              transition="scale-y"
              transitionDuration={300}
            >
              <div style={{ display: "flex" }}>
                <IconButton
                  disabled={isViewOnly}
                  className="sort-icon-button"
                  onClick={deleteRecords}
                  icon={
                    <img
                      src={selectedKeys?.length ? deleteBlackIcon : deleteIcon}
                      alt="delete-icon"
                    />
                  }
                />
              </div>
            </Tooltip>
            <div style={{ display: "flex" }}>
              <IconButton
                disabled={isViewOnly}
                className="sort-icon-button"
                onClick={handleChangeOpenSearch}
                icon={
                  <img
                    src={openSearch ? searchBlackIcon : searchIcon}
                    alt="search"
                  />
                }
              />
            </div>
          </div>
        )}
        renderDropdown={() => (
          <DropdownComponents
            isSortSelected={isSortSelected}
            isFilterSelected={isFilterSelected}
            sortMenu={sortMenu}
            filterMenu={filterMenu}
            contacts={contacts}
            buckets={buckets}
            onSortSelect={onSortSelect}
            onFilterSelect={onFilterSelect}
            handleFilterMenuClose={handleFilterMenuClose}
            handleSortMenuClose={handleSortMenuClose}
            filterDropdownList={filterDropdownList}
            sortDropdown={
              isForecastBudgetTab() ? sortDropdownEstimates : sortDropdown
            }
          />
        )}
        selectItemId={parseInt(
          isForecastBudgetTab()
            ? searchParams.get("selectedForecastId")
            : searchParams.get("selectedBillId")
            ? searchParams.get("selectedBillId")
            : recordsToDisplay?.length
            ? recordsToDisplay[0].id
            : ""
        )}
        onCardClick={(clickedItem: any) => {
          if (isForecastBudgetTab()) {
            setSearchParams({
              tab: searchParams.get("tab"),
              selectedForecastId: clickedItem.id,
            });
            window.localStorage.setItem(
              "selectedForecastIdPay",
              clickedItem.id
            );
            window.localStorage.removeItem("selectedBillId");
          } else {
            setSearchParams({
              tab: searchParams.get("tab"),
              selectedBillId: clickedItem.id,
            });
            window.localStorage.setItem("selectedBillId", clickedItem.id);
            window.localStorage.removeItem("selectedForecastIdPay");
          }
        }}
      />
    </ListContainer>
  );
}

export default LeftView;

const Badge = styled.div`
  border: 1px solid #2c6c76;
  color: #408180;
  height: fit-content;
  padding: 5px;
  border-radius: 5px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  min-width: 150px;
  max-width: 150px;
`;
