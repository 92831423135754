import React from "react";
import {
  Modal,
  Alert,
  Paper,
  Table,
  Text,
  Group,
  Badge,
  Box,
  Button,
} from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faTimesCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { COLORS } from "../../constants/theme";

const DuplicateInvoicesModal = ({
  isOpen,
  onClose,
  stats,
  duplicates,
  onConfirm,
}: any) => {
  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      closeOnClickOutside={false}
      closeOnEscape={false}
      title={
        <Text size="lg" weight={600} color={COLORS.dustyOrange}>
          <Group spacing="xs">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            Duplicate Invoices Detected
          </Group>
        </Text>
      }
      size="lg"
      styles={{
        modal: {
          zIndex: 1050,
        },
        overlay: {
          zIndex: 1049,
        },
      }}
    >
      <Box>
        {/* Upload Summary */}
        <Alert
          icon={<FontAwesomeIcon icon={faInfoCircle} />}
          color="blue"
          mb={15}
        >
          <Group position="apart">
            <Text weight={500}>Upload Results</Text>
            <Group spacing="xs">
              <Badge color="blue" size="lg">
                Total: {stats.totalInvoices}
              </Badge>
              <Badge color="orange" size="lg">
                Duplicates: {stats.duplicatesFound}
              </Badge>
              <Badge color="green" size="lg">
                Added: {stats.newInvoicesAdded}
              </Badge>
            </Group>
          </Group>
        </Alert>

        {/* Duplicates List */}
        <Text size="sm" mb={10}>
          The following invoices already exist in the system and were not
          imported:
        </Text>

        <Text size="sm" mb={10}>
          Preview of First 20 Invoices:
        </Text>

        <Paper mb={20}>
          <Box sx={{ maxHeight: "300px", overflow: "auto" }}>
            <Table striped highlightOnHover>
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "white",
                  zIndex: 1,
                }}
              >
                <tr>
                  <th>Invoice #</th>
                  <th>Contact Name</th>
                </tr>
              </thead>
              <tbody>
                {duplicates.slice(0, 20).map((invoice: any) => (
                  <tr key={invoice.invoiceNumber}>
                    <td>{invoice.invoiceNumber}</td>
                    <td>{invoice.contactName}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Box>
        </Paper>

        {/* Actions */}
        <Group position="right">
          <Button variant="filled" color="teal" onClick={onConfirm}>
            Got it
          </Button>
        </Group>
      </Box>
    </Modal>
  );
};

export default DuplicateInvoicesModal;
