import "./styles.css";
import React from "react";
import { Bar } from "react-chartjs-2";
import { MONTHS_LIST } from "../../constants/globalConstants";
import Card from "../Card/Card";
import { CardSubTitle } from "../CardSubTitle";
import { CardTitle } from "../CardTitles";
import { getDateRangeMonths } from "../../modules/getDateRangeMonths";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import CardSubtilteSmall from "../CardTitles/CardSubtilteSmall";
import { GraphLegend } from "../GraphLegend";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);

interface DataType {
  title: string;
  subTitle: string;
  data: any[];
  backgroundColor: string;
  borderColor: string;
  borderWidth?: number;
  borderRadius?: number;
}

interface BarChartDataType {
  data: DataType;
  isCashIn: boolean;
}

const formatCurrency = (value: string) => {
  return value.replace(/\.\d+$/, "");
};

function BarChart({
  data: { title, subTitle, data, backgroundColor, borderColor },
  isCashIn,
}: BarChartDataType) {
  const { months } = getDateRangeMonths();
  return (
    <Card
      className="m-1 is-flex is-flex-direction-column is-justify-content-space-between"
      style={{ minHeight: "48%", maxHeight: "48%" }}
    >
      <div>
        <CardTitle title={title} />
        <CardSubTitle title={formatCurrency(subTitle)} />
      </div>
      <Bar
        data={{
          labels: months,
          datasets: [
            {
              label: isCashIn ? "Average Cash In" : "Average Cash Out",
              data: data,
              backgroundColor: [backgroundColor],
              borderColor: [borderColor],
              borderWidth: 1,
              borderRadius: 100,
            },
          ],
        }}
        options={{
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              titleFont: {
                size: 13,
              },
              bodyFont: {
                size: 13,
              },
            },
          },
          scales: {
            x: {
              ticks: {
                display: false,
              },

              grid: {
                drawBorder: false,
                display: false,
              },
            },
            y: {
              ticks: {
                display: false,
              },
              grid: {
                drawBorder: false,
                display: false,
              },
            },
          },
        }}
      />
    </Card>
  );
}

export default BarChart;
