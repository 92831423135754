import axios from "axios";
import { configs } from "../../../constants";

export const getRecord = async (id: string) => {
  try {
    const { data } = await axios.post(
      configs.urls.BASE_URL + "/invoice/get",
      {
        id,
      },
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return data.response;
    } else {
      return "error";
    }
  } catch (error) {
    return "error";
  }
};

export const getContactById = async (id: string) => {
  try {
    const { data } = await axios.post(
      configs.urls.BASE_URL + `/contact/get/${id}`,
      {},
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return data.response;
    } else {
      return "error";
    }
  } catch (error) {
    return "error";
  }
};

type GetRecordsByContactName = {
  contactId: string | number;
  dateRange: [string, string];
  skip: number;
  type: any;
};
export const getRecordsByContact = async (
  dataToSave: GetRecordsByContactName
) => {
  try {
    const { data } = await axios.post(
      configs.urls.BASE_URL + "/invoice/getByContact",
      {
        ...dataToSave,
      },
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return { success: true, data: data.response };
    } else {
      return { success: false, data: data.errors };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};

export const getCashflowRecord = async (
  forCategory: string,
  offset: number
) => {
  try {
    const { data } = await axios.post(
      configs.urls.BASE_URL + "/cashflow_scenario/cashflow_records/get",
      {
        forCategory,
        offset,
      },
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return data.response;
    } else {
      return "error";
    }
  } catch (error) {
    return "error";
  }
};

export const getCashflowRecordUpdated = async (
  forCategory: string,
  offset: number
) => {
  try {
    const { data } = await axios.post(
      configs.urls.BASE_URL + "/cashflow_scenario/generate_dynamic/get",
      {
        forCategory,
        offset,
      },
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return data;
    } else {
      return "error";
    }
  } catch (error) {
    return "error";
  }
};
