import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../constants/theme";

function LoadMore({
  onClick = () => {},
  loading = false,
}: {
  onClick?: () => void;
  loading?: boolean;
}) {
  return (
    <tr>
      <td
        style={{
          border: "none",
          display: "data-cell",
          verticalAlign: "middle",
          position: "sticky",
          left: 0,
          top: 0,
        }}
        className="expandable-table-row "
      >
        <LoadMoreButton
          onClick={onClick}
          disabled={loading}
          style={{
            color: COLORS.greenBlue,
            outline: "none",
          }}
          className={`button is-text pt-1 ${loading && "is-loading"}`}
        >
          Load more
        </LoadMoreButton>
      </td>
    </tr>
  );
}

export default LoadMore;
const LoadMoreButton = styled.button`
  font-weight: 700;
  font-size: 12px;
  /* remove all borders */
  border: none;
  /* remove all margins */
  margin: 0;
  /* remove all paddings */
  /* remove all background */
  background: none;
  margin-left: 40px;
  /* remove all outline */
  outline: none;
  :focus :active {
    outline: none;
    border: none;
    margin: 0;
    background: none;
  }
`;
