import axios from "axios";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

function SubSuccess() {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("session_id");

  //   const checkSubscriptionStatus = async (sessionId: string) => {
  //     try {
  //         const response = await axios.get();
  //     } catch (error) {
  //         console.log("Check subscription error: ", error)
  //     }
  //   }

  useEffect(() => {
    if (sessionId) {
      console.log("Sub success");
      console.log("Session ID:", sessionId);

      //   // Call backend to verify the session and update the user's plan
      //   fetch(`/api/verify-subscription?session_id=${sessionId}`)
      //     .then((res) => res.json())
      //     .then((data) => console.log("Subscription Verified:", data))
      //     .catch((err) => console.error("Error verifying subscription:", err));
    }
  }, [sessionId]);

  return <div>SubSuccess</div>;
}

export default SubSuccess;
