import { Modal, Group, GroupPosition } from "@mantine/core";
import { COLORS } from "../../constants/theme";

interface CustomModalInterface {
  title: string;
  isOpen: boolean;
  handleClose: any;
  children: any;
  buttonText?: string;
  handleButtonClick: any;
  buttonColor?: string;
  className?: string;
  withCloseButton?: boolean;
  buttonPosition?: GroupPosition;
  recordsLoading?: boolean;
  disableClose?: boolean;
}
export const CustomModal = ({
  title,
  isOpen,
  handleClose,
  children,
  buttonText,
  handleButtonClick,
  className,
  withCloseButton,
  buttonPosition,
  recordsLoading,
  disableClose,
}: CustomModalInterface) => {
  return (
    <Modal
      className={className}
      centered={true}
      size="auto"
      opened={isOpen}
      withCloseButton={withCloseButton === false ? false : true}
      onClose={disableClose ? undefined : handleClose}
      title={title}
    >
      {children}
      {buttonText && (
        <Group position={buttonPosition || "right"}>
          <button
            type="submit"
            className={`button mv-6 is-bold is-small ${
              recordsLoading ? "is-loading" : ""
            }`}
            onClick={handleButtonClick}
            autoFocus={false}
            style={{
              backgroundColor: COLORS.greenBlue,
              color: COLORS.white,
              width: "15rem",
            }}
            disabled={recordsLoading}
          >
            <span>{buttonText}</span>
          </button>
        </Group>
      )}
      <br />
    </Modal>
  );
};
