import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { IMAGES } from "../../../constants";
import { PaymentStatus } from "../../../constants/globalConstants";
import { formatCurrency } from "../../../modules";
import { cutLengthyString } from "../../../modules/cutLengthyString";
import { getHighlightedText } from "../../../modules/getHighlightedText";
import { getLatestTransaction } from "../../../modules/getLatestTransaction";

function RecordListCard({
  record,
  searchText = "",
}: {
  record: any;
  searchText?: string;
}) {
  // console.log("Active record >>>>> ", record);

  return (
    <div style={{ position: "relative" }}>
      {/* Header */}
      <div className="flex-direction-row is-justify-content-space-between">
        <div className="flex-direction-row">
          <figure className="image mr-3" style={{ width: 40, height: 40 }}>
            <img
              className="is-rounded"
              src={IMAGES.company_logo_placeholder}
              alt=""
            />
          </figure>
          <div className="subtitle ml-2 mt-1" style={{ fontWeight: "700" }}>
            {getHighlightedText(cutLengthyString(record?.name, 25), searchText)}
          </div>
        </div>
        {record.status === "Unpaid" &&
          moment(record.dueDate).toISOString() < moment().toISOString() && (
            <button className="overdue-status">Overdue!</button>
          )}

        {record.status === "PartiallyPaid" && (
          <button className="overdue-status orange">Partially Paid</button>
        )}
      </div>

      {/* Details */}
      <div className="flex-direction-row-space-between date-amount-container">
        <div>
          <div className="card-date">
            {record.status === PaymentStatus.Paid
              ? getHighlightedText(
                  "Paid on " +
                    moment(
                      getLatestTransaction(record?.linkedTransactions)
                        ?.paidOrFailedAt
                    ).format("MMM, DD, YYYY"),
                  searchText
                )
              : getHighlightedText(
                  `Due ${moment(record.expectedDueDate).format(
                    "MMM, DD, YYYY"
                  )}`,
                  searchText
                )}
          </div>
          <div className="card-code">
            {_.isEmpty(record.invoiceNumber) || !record.invoiceNumber.trim() ? (
              <i>No invoice number.</i>
            ) : (
              getHighlightedText(`#${record.invoiceNumber}`, searchText)
            )}
          </div>
        </div>
        <div className="subtitle ml-4" style={{ fontWeight: "700" }}>
          {record.currency || "AED"}{" "}
          {getHighlightedText(
            formatCurrency(parseFloat(record?.amountTotal || "0"), 2) || "",
            searchText
          )}
        </div>
      </div>
      {record.image && (
        <div style={{ position: "absolute", top: 0, right: 0 }}>
          <FontAwesomeIcon icon={faPaperclip} />
        </div>
      )}
    </div>
  );
}

export default RecordListCard;
