import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Loader, Popover, Whisper } from "rsuite";
import styled from "styled-components";
import CustomButtonPrimary from "../../../common/CustomButtonPrimary/CustomButtonPrimary";
import { LoadingPlaceholders } from "../../../common/LoadingPlaceholders";
import SkipButton from "../../../common/SkipButton/SkipButton";
import { configs, IMAGES } from "../../../constants";
import {
  InvoiceTypes,
  PeriodFilter,
  Tier,
} from "../../../constants/globalConstants";

import { useAuth } from "../../../contexts";
import { getUserLS, setUserLS } from "../../../modules/localStorage";
import { updateOrganization } from "../../../repositories/organizationRepository";
import { getUserWithActiveOrganization } from "../../../repositories/userRepository";
import ActionsMenu from "./ActionsMenu";
import AddRecordDrawer from "./AddRecordDrawer/AddRecordDrawer";
import CustomDatePicker from "./CustomDatePicker";
import FilterCashflowMenu from "./FilterCashflowMenu";
import GroupIntoBuckets from "./GroupIntoBuckets";
import ManualBalance from "./ManualBalance";
import { SwitchViewTabs } from "./SwitchViewTabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckCircle,
  faChevronDown,
  faCloud,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import ColorCoding from "./ColorCoding";
import {
  Group,
  HoverCard,
  NavLink,
  Text,
  TextInput,
  Tooltip,
  Skeleton,
} from "@mantine/core";
import { COLORS } from "../../../constants/theme";
import {
  IconArrowBarRight,
  IconArrowRight,
  IconExternalLink,
  IconLink,
} from "@tabler/icons";
import { useNavigate } from "react-router-dom";
import { currentPlansMaxScenarioDate } from "../../../modules/doPlanAllow";
import { isUserViewOnly } from "../../../modules/localStorage";

interface ConfigsHeaderProps {
  selectedViewState: any;
  searchParams: any;
  isSaving?: boolean;
  setCurrentPage?: any;
}

function ConfigsHeader({
  selectedViewState,
  isSaving,
  setCurrentPage,
}: ConfigsHeaderProps) {
  const { selectedViewIndex, setSelectedViewIndex } = selectedViewState;
  const { isDemo } = getUserLS();

  // modals
  const [addRecordsDrawer, showAddRecordsDrawer] = useState(false);
  const [invoiceType, setInvoiceType] = useState(InvoiceTypes.Payable);
  const [showEditLayoutModal, setShowEditLayoutModal] = useState(false);
  const [showBalanceEditModal, setShowBalanceEditModal] = useState(false);
  const [organization, setOrganization] = useState<any>();
  const navigate = useNavigate();

  const {
    cashflowConfigs,
    setCashflowConfigs,
    configsLoading,
    fetchCashflowReport,
    reportLoading,
    setReportLoading,
    getRecords,
    getReportLayout,
    cashflowStage,
    setCashflowStage,
    baseCurrency,
    selectedScenario,
  } = useAuth();

  const isViewOnly = isUserViewOnly();

  const getActiveOrganization = async () => {
    const org = await getUserWithActiveOrganization();
    setOrganization(org);
  };
  useEffect(() => {
    getActiveOrganization();
  }, []);

  const handleExpectedDueDateChange = (value: boolean) => {
    setCashflowConfigs({
      ...cashflowConfigs,
      filterByExpectedDueDate: value,
    });
  };

  const handleGroupByContacts = (value: boolean) => {
    setCashflowConfigs({
      ...cashflowConfigs,
      groupByContacts: value,
    });
  };

  const handleOnSkip = async () => {
    setCashflowStage(8);
    setUserLS({ completedTutorial: true });
    await updateOrganization({ completedTutorial: true });
  };

  const minStartDateShouldBe = () => {
    if (organization) {
      const latestDate = new Date().toISOString();
      let minDate: any = moment(latestDate)
        .subtract(12, "month")
        .startOf("month")
        .toDate();

      // if (organization.isDemo || organization.name.includes("Demo")) {
      //   minDate = moment(latestDate)
      //     .subtract(6, "month")
      //     .startOf("month")
      //     .toDate();
      // }

      if (organization?.subscription?.tier === Tier.Demo) {
        minDate = moment(latestDate)
          .subtract(6, "month")
          .startOf("month")
          .toDate();
      }

      if (cashflowConfigs.periodFilter === PeriodFilter.Monthly) {
        return moment(minDate).startOf("month").toDate();
      } else if (cashflowConfigs.periodFilter === PeriodFilter.Weekly) {
        return moment(minDate).add(1, "week").startOf("week").toDate();
      } else return moment(minDate).toDate();
    }
  };

  // const maxStartDateShouldBe = () => {
  //   if (cashflowConfigs) {
  //     // if (cashflowConfigs.periodFilter === PeriodFilter.Monthly) {
  //     return moment(cashflowConfigs.endDate)
  //       .add(1, "day")
  //       .startOf("month")
  //       .toDate();
  //     // }
  //   } else {
  //     return undefined;
  //   }
  // };

  const currentStartDate = (startDate: Date) => {
    if (cashflowConfigs && organization) {
      // const latestDate = new Date().toISOString();
      let minDate = moment(cashflowConfigs.minStartDate).startOf("month");

      // // if (organization.isDemo || organization.name.includes("Demo")) {
      // //   minDate = moment(latestDate).subtract(6, "month").startOf("month");
      // // }

      // if (organization?.subscription?.tier === Tier.Demo) {
      //   minDate = moment(latestDate).subtract(6, "month").startOf("month");
      // }

      let currentDate = moment(startDate).isBefore(minDate)
        ? minDate
        : startDate;

      if (cashflowConfigs.periodFilter === PeriodFilter.Weekly) {
        if (moment(startDate).isoWeekday() !== 7) {
          return moment(currentDate)
            .clone()
            .add(1, "week")
            .startOf("week")
            .toDate();
        }
        return moment(currentDate).toDate();
      } else if (cashflowConfigs.periodFilter === PeriodFilter.Monthly) {
        if (moment(startDate).date() !== 1) {
          return moment(currentDate).clone().startOf("month").toDate();
        } else return moment(currentDate).toDate();
      } else {
        // startDate = currentUsersOrg.createdAt;
        return moment(currentDate).toDate();
      }
    }
  };

  const speaker = (
    <Popover
      title="View your cashflow"
      className="tutorial-popover"
      arrow={false}
    >
      <div className="popover-container">
        <p className="tutorial-popover-subtitle">
          Set the start and end date of your cashflow to view <br /> your
          historical data and forecasted data. You can
          <br /> also breakdown the view from monthly to weekly or
          <br /> even a daily view.
        </p>

        <br />
        <br />
        <CustomButtonPrimary handleOnClick={() => setCashflowStage(5)} />
        <SkipButton handleOnClick={handleOnSkip} />
      </div>
    </Popover>
  );

  const renderStartDay = (date: Date) => {
    const day = date.getDate();
    if (cashflowConfigs.periodFilter === PeriodFilter.Weekly && day === 0) {
      return (
        <div
          style={{
            fontWeight: "700",
            backgroundColor: "#0f02",
            borderRadius: 5,
          }}
        >
          {day}
        </div>
      );
    } else if (
      cashflowConfigs.periodFilter === PeriodFilter.Monthly &&
      moment(date).startOf("month").format("DD") === moment(date).format("DD")
    ) {
      return (
        <div
          style={{
            fontWeight: "700",
            backgroundColor: "#0f02",
            borderRadius: 5,
          }}
        >
          {day}
        </div>
      );
    } else {
      return <div>{day}</div>;
    }
  };

  const excludeDate = (date: Date) => {
    if (cashflowConfigs.periodFilter === PeriodFilter.Weekly) {
      return !(date.getDay() === 0);
    } else if (cashflowConfigs.periodFilter === PeriodFilter.Monthly) {
      return false;
      // return (
      //   moment(date).startOf("month").format("DD") !== moment(date).format("DD")
      // );
    } else if (cashflowConfigs.periodFilter === PeriodFilter.Daily) {
      return false;
    }
  };

  const handleSwitchTabs = (value: number) => {
    const valueChanged =
      value === 0
        ? PeriodFilter.Monthly
        : value === 1
        ? PeriodFilter.Weekly
        : value === 2
        ? PeriodFilter.Daily
        : PeriodFilter.Monthly;

    if (valueChanged !== cashflowConfigs.periodFilter) {
      setReportLoading(true);
    }

    if (value === 0) {
      setCashflowConfigs({
        ...cashflowConfigs,
        periodFilter: PeriodFilter.Monthly,
      });
      setCurrentPage(1);
    } else if (value === 1) {
      setCashflowConfigs({
        ...cashflowConfigs,
        periodFilter: PeriodFilter.Weekly,
      });
      setCurrentPage(1);
    } else if (value === 2) {
      setCashflowConfigs({
        ...cashflowConfigs,
        periodFilter: PeriodFilter.Daily,
      });
      setCurrentPage(1);
    }
  };

  const handleStartDateChange = (date: Date) => {
    setCurrentPage(1);
    let offset = date.getTimezoneOffset();
    let offsetInHours = offset / 60;
    let offsetInMilliseconds = offsetInHours * 60 * 60 * 1000;
    let newDate = new Date(date.getTime() - offsetInMilliseconds);

    setCashflowConfigs((prev: any) => ({
      ...prev,
      startDate: moment(newDate).toDate().toISOString(),
    }));
  };

  const handleEndDateChange = (date: Date) => {
    setCurrentPage(1);
    let offset = date.getTimezoneOffset();
    let offsetInHours = offset / 60;
    let offsetInMilliseconds = offsetInHours * 60 * 60 * 1000;
    let newDate = new Date(date.getTime() - offsetInMilliseconds);

    const newEndDate = moment(newDate).toDate().toISOString();

    const maxStartDate = currentStartDate(moment(newEndDate).toDate());

    setCashflowConfigs((prev: any) => {
      let adjustedStartDate = prev.startDate;
      if (maxStartDate && moment(maxStartDate).isAfter(prev.startDate)) {
        adjustedStartDate = moment(maxStartDate).toISOString();
      }

      return {
        ...prev,
        endDate: newEndDate,
      };
    });
  };

  // useEffect(() => {
  //   const cashflowStartDate = moment(cashflowConfigs.startDate).toISOString();

  //   const currentStartDateVar = moment(
  //     currentStartDate(cashflowConfigs?.startDate)
  //   ).toDate();

  //   if (new Date(cashflowStartDate) < currentStartDateVar) {
  //     const offsetInMilliseconds =
  //       currentStartDateVar.getTimezoneOffset() * 60000;
  //     const adjustedDate = new Date(
  //       currentStartDateVar.getTime() - offsetInMilliseconds
  //     );

  //     setCashflowConfigs((prev: any) => ({
  //       ...prev,
  //       startDate: moment(adjustedDate).toISOString(),
  //     }));
  //   }
  // }, [cashflowConfigs, minStartDateShouldBe, currentStartDate]);

  useEffect(() => {
    const cashflowStartDate = moment(cashflowConfigs.startDate).toISOString();
    const minStartDate = moment(cashflowConfigs.minStartDate).toDate();

    const currentStartDateVar = moment(
      currentStartDate(cashflowConfigs?.startDate)
    ).toDate();

    if (new Date(cashflowStartDate) < minStartDate) {
      const offsetInMilliseconds = minStartDate.getTimezoneOffset() * 60000;
      const adjustedDate = new Date(
        minStartDate.getTime() - offsetInMilliseconds
      );

      setCashflowConfigs((prev: any) => ({
        ...prev,
        startDate: moment(adjustedDate).toISOString(),
      }));
    }
  }, [cashflowConfigs, minStartDateShouldBe, currentStartDate]);

  const navigateToCurrencySettings = () =>
    navigate("/settings/?active=currencies");

  const maxEndDate = () => {
    if (organization?.subscription) {
      const maxEndDate = currentPlansMaxScenarioDate(
        organization?.subscription,
        minStartDateShouldBe() || new Date()
      );
      return maxEndDate;
    } else return undefined;
  };

  return (
    <div
      style={{
        maxWidth: "93vw",
        minWidth: "93vw",
        display: "flex",
        flexDirection: "column",
      }}
      className="mb-2"
    >
      <div className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
        <CashflowTitle className="cashflow-filters mb-3">
          <span>{selectedScenario?.name}</span>
          {/* <FontAwesomeIcon icon={faChevronDown} /> */}
        </CashflowTitle>
        <Saving>
          {isSaving ? (
            <Fragment>
              <span>Saving cashflow...</span>{" "}
              <Loader size="sm" className="mx-2" />
            </Fragment>
          ) : (
            <Fragment>
              <span>Cashflow saved</span>{" "}
              <FontAwesomeIcon
                size="sm"
                className="mx-2"
                icon={faCheckCircle}
                color={"#03b37b"}
              />
            </Fragment>
          )}
        </Saving>
      </div>
      <div className="flex-direction-row is-align-flex-end is-justify-content-space-between mb-3">
        {configsLoading ? (
          <LoadingPlaceholders.Text
            width={900}
            height={30}
            active
            style={{ marginLeft: 30 }}
          />
        ) : (
          <ConfigsContainer className="ml-2">
            {/* <SearchBar
              className="ml-2 mr-2"
              placeholder="Search"
              type="text"
              icon={<FontAwesomeIcon size="lg" icon={faSearch} />}
              styles={{
                input: {
                  border: "solid 1px #bdc0c2",
                  width: "200px",
                  height: "35px",
                  borderRadius: "5px",
                  padding: "0px 10px",
                  boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.1)",
                  ":focus": {
                    border: "solid 2px #616669",
                  },
                },
              }}
            /> */}
            <div className="flex-direction-row">
              {/* <FilterCashflowMenu
                cashflowConfigs={cashflowConfigs}
                handleExpectedDueDateChange={handleExpectedDueDateChange}
                handleGroupByContacts={handleGroupByContacts}
              /> */}
              <ActionsMenu
                reportLoading={reportLoading}
                isViewOnly={isViewOnly}
                setInvoiceType={setInvoiceType}
                setShowEditLayoutModal={setShowEditLayoutModal}
                showAddRecordsDrawer={showAddRecordsDrawer}
              />
            </div>

            <ColorCoding />

            {/* <button
              className="button"
              onClick={() => {
                navigate("/cashflow?tab=history");
              }}
            >
              Txn History
            </button> */}
          </ConfigsContainer>
        )}

        <Whisper
          placement="bottomStart"
          open={cashflowStage === 4}
          speaker={speaker}
        >
          <div
            className={`is-flex ${
              cashflowStage === 4
                ? "tutorial-btn-container is-align-items-flex-end"
                : ""
            } ${
              reportLoading
                ? "config-header-logo-style"
                : "is-align-items-flex-end"
            }`}
          >
            {reportLoading ? (
              <>
                <Skeleton height={30} width={"180px"} radius="sm" />
              </>
            ) : (
              <BaseCurrency>
                <label>Base Currency: </label>
                <Group position="center">
                  <HoverCard
                    disabled={isViewOnly}
                    shadow="md"
                    position="top"
                    withArrow
                    transition={"skew-down"}
                    transitionDuration={400}
                  >
                    <HoverCard.Target>
                      <Currency>{baseCurrency}</Currency>
                    </HoverCard.Target>
                    <HoverCard.Dropdown>
                      <ChangeCurrency onClick={navigateToCurrencySettings}>
                        <span>Change currency</span>
                        <IconArrowRight />
                      </ChangeCurrency>
                    </HoverCard.Dropdown>
                  </HoverCard>
                </Group>
              </BaseCurrency>
            )}

            {reportLoading ? (
              <>
                <Skeleton height={30} width={"180px"} radius="sm" />
              </>
            ) : (
              <>
                <CustomDatePicker
                  onDateChange={handleStartDateChange}
                  // date={currentStartDate(
                  //   moment(cashflowConfigs?.startDate).toDate()
                  // )}
                  date={moment(cashflowConfigs?.startDate).toDate()}
                  label="Starting from"
                  excludeDate={excludeDate}
                  // renderDay={renderStartDay}
                  minDate={moment(cashflowConfigs?.minStartDate).toDate()}
                  maxDate={moment(cashflowConfigs?.endDate).toDate()}
                  // isDemoMaxDate={
                  //   organization?.subscription?.tier === Tier.Demo
                  //     ? maxEndDate()
                  //     : undefined
                  // }
                />

                <CustomDatePicker
                  onDateChange={handleEndDateChange}
                  date={moment(cashflowConfigs?.endDate).toDate()}
                  minDate={
                    currentStartDate(
                      moment(cashflowConfigs?.startDate).toDate()
                    )
                      ? currentStartDate(
                          moment(cashflowConfigs?.startDate).toDate()
                        )
                      : moment(cashflowConfigs.startDate).toDate()
                  }
                  label="Ending on"
                  maxDate={moment(cashflowConfigs?.maxEndDate).toDate()}
                  // isDemoMinDate={
                  //   organization?.subscription?.tier === Tier.Demo
                  //     ? currentStartDate(
                  //         moment(cashflowConfigs?.startDate).toDate()
                  //       )
                  //     : undefined
                  // }
                />
              </>
            )}

            {reportLoading ? (
              <Skeleton height={30} width={"180px"} radius="sm" />
            ) : (
              <div>
                <SwitchViewTabs
                  active={selectedViewIndex}
                  setSelectedView={handleSwitchTabs}
                />
              </div>
            )}
          </div>
        </Whisper>
      </div>

      {/* <ColorCodesContainerSmallerScreen>
        <ColorCode title="Opening Balance in the past">
          <Color style={{ backgroundColor: "#408180" }} title="Green" />
          <Title>Opening Balance in the past</Title>
        </ColorCode>
        <ColorCode title="Opening Balance in the future">
          <Color style={{ backgroundColor: "purple" }} title="Purple" />
          <Title>Opening Balance in the future</Title>
        </ColorCode>
        <ColorCode title="Opening Balance from Uploaded Bank Statements">
          <Color style={{ backgroundColor: "orange" }} title="Orange" />
          <Title>Opening Balance from Uploaded Bank Statements</Title>
        </ColorCode>
        <ColorCode title="Calculated Opening Balance">
          <Color style={{ backgroundColor: "black" }} title="Black" />
          <Title>Calculated Opening Balance</Title>
        </ColorCode>
      </ColorCodesContainerSmallerScreen> */}

      <GroupIntoBuckets
        drawer={showEditLayoutModal}
        showDrawer={setShowEditLayoutModal}
        onSuccess={async () => {
          setReportLoading(true);
          // await fetchCashflowReport();
          await getRecords();
          getReportLayout();
        }}
      />

      <ManualBalance
        drawer={showBalanceEditModal}
        showDrawer={setShowBalanceEditModal}
        onSuccess={async () => {
          setReportLoading(true);
          fetchCashflowReport();
          setShowBalanceEditModal(false);
        }}
      />

      <AddRecordDrawer
        drawer={addRecordsDrawer}
        showDrawer={showAddRecordsDrawer}
        recordType={invoiceType}
      />
    </div>
  );
}

export default ConfigsHeader;

const CashflowTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 10px 25px;
  cursor: pointer;

  span {
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
  }
`;

const ConfigsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Saving = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10px;

  span {
    font-size: 13px;
    font-weight: 600;
    color: #647787;
    margin-top: 1px;
  }

  border-radius: 5px;
  border: 1px solid #647787;

  padding: 5px 5px 5px 10px;
`;

const SearchBar = styled(TextInput)`
  width: 200px;
  height: 35px;

  border-radius: 5px;
  padding: 0px 10px;
  margin-left: 18px;
`;

const BaseCurrency = styled.div`
  display: flex;
  align-items: center;
  margin: 6px 10px;

  > label {
    color: ${COLORS.brownGrey};
    margin-right: 5px;
    font-size: 12px;
  }
`;

const ChangeCurrency = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  gap: 8px;
`;

const Currency = styled.div`
  color: ${COLORS.black};
  font-size: 12px;
  font-weight: 700;
`;
