import "../styles.css";
import { useState, useEffect, useMemo } from "react";
import Switch from "react-switch";
import _, { cloneDeep } from "lodash";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import Card from "../../../common/Card/Card";
import { CardSubTitle } from "../../../common/CardSubTitle";
import { CardTitle } from "../../../common/CardTitles";
import { financialHealth } from "../../../constants/images";
import { LineChart } from "../../../common/LineChart";
import { BarChart } from "../../../common/BarChart";

import {
  AvgBurnRateData,
  AvgCashInData,
  cashflowDatasets,
  legendsCashFlow,
} from "../../../constants/dummyData";
import { useAuth } from "../../../contexts";
import ProgressBarCircular from "../../../common/ProgressBarCIrcular/ProgressBarCircular";
import { ICONS } from "../../../constants";
import { formatCurrency } from "../../../modules";
import { Placeholder } from "rsuite";
import { useScrollBlock } from "../../../hooks/useScrollBlock";
import { CashflowTitles } from "../../Cashflow/constants";
import moment from "moment";
import {
  PeriodFilter,
  ReportViewTypes,
} from "../../../constants/globalConstants";
// const url = process.env.REACT_APP_SOCKET_URL || "http://localhost:4000";
// const socket = io(url);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function CompanyDashboard() {
  useScrollBlock();
  const {
    baseCurrency,
    dashboardData: cashflowReport,
    dashboardLoading,
    cashflowConfigs,
  } = useAuth();

  const [isChecked, setIsChecked] = useState(false);
  // const [loading, setLoading] = useState(false);

  const [cashFlowData, setCashFlowData] = useState(cashflowDatasets);
  const [cashflowLegends, setCashFlowLegends] = useState(legendsCashFlow);

  const [dashboardDateValues, setDashboardDateValues] = useState([]);

  const [avgCashInData, setAvgCashInData] = useState(AvgCashInData);
  const [avgBurnRateData, setAvgBurnRateData] = useState(AvgBurnRateData);
  const [runWay, setRunWay] = useState<any>({
    value: 0,
    months: 0,
    lastRunwayMonth: "",
    netRunwayVal: 0,
    lastNetRunwayMonth: "",
  });

  const getCashflowHeader = () => {
    return (
      cashflowReport?.find(
        (element: any) => element?.name === CashflowTitles.CashflowStatement
      )?.values || []
    );
  };

  let numberOfColumns = useMemo(
    () => getCashflowHeader()?.length,
    [cashflowReport]
  );

  const getRowValues = (title: string, negate: number = 1) =>
    cashflowReport
      ?.find((element: any) => element?.name === title)
      ?.values.map((value: any) => negate * parseFloat(value?.value)) ||
    Array(numberOfColumns).fill(0);

  useEffect(() => {
    let balanceOverDateRange: number[] = getRowValues(
      CashflowTitles.ClosingBalance
    );

    let cashInOverDateRange: number[] = getRowValues(CashflowTitles.CashIn);
    let cashOutOverDateRange: number[] = getRowValues(
      CashflowTitles.CashOut,
      -1
    );
    let unreconciledOverDateRange: number[] = getRowValues(
      CashflowTitles.Unreconciled
    );

    const cashFlow: any = cloneDeep(cashFlowData);
    cashFlow[0].data = balanceOverDateRange;
    cashFlow[1].data = cashInOverDateRange;
    cashFlow[2].data = cashOutOverDateRange;
    cashFlow[3].data = unreconciledOverDateRange;

    setCashFlowData(cashFlow);

    const currentDate = moment();
    let currentDateIndex = getCashflowHeader().findIndex((column: string) =>
      moment(column).startOf("month").isSame(currentDate.startOf("month"))
    );
    
    let totalCashIn = _.sum(cashInOverDateRange);
    let totalCashOut = _.sum(cashOutOverDateRange);
    let totalUnrec = _.sum(getRowValues(CashflowTitles.Unreconciled));
    let currentClosingBalance =
      currentDateIndex !== -1 ? balanceOverDateRange[currentDateIndex] : 0;
    let _avgCashIn = _.mean(cashInOverDateRange);
    let avgCashOut = Math.abs(_.mean(cashOutOverDateRange));

    const legends = cloneDeep(cashflowLegends);
    legends[1].value = `${baseCurrency} ${
      formatCurrency(totalCashIn || 0, 2) || 0
    }`;
    legends[2].value = `${baseCurrency} ${
      formatCurrency(totalCashOut || 0, 2) || 0
    }`;
    legends[0].value = `${baseCurrency} ${
      formatCurrency(currentClosingBalance, 2) || 0
    }`;

    legends[3].value = `${baseCurrency} ${formatCurrency(totalUnrec, 2) || 0}`;

    // Average Cash In
    const avgCashIn = cloneDeep(avgCashInData);
    avgCashIn.subTitle = `${baseCurrency} ${formatCurrency(
      _avgCashIn || 0,
      2
    )}`;
    avgCashIn.data = cashInOverDateRange;

    setAvgCashInData(avgCashIn);

    let runwayMonths = 0;
    let runwayDateRelativeToCurrentDate = "";
    let netRunwayMonths: string | number = 0;
    let netRunwayDateRelativeToCurrentDate = "";

    if (currentClosingBalance <= 0) {
      runwayMonths = 0;
    } else {
      runwayMonths = Math.trunc(currentClosingBalance / avgCashOut) || 0;
    }

    runwayMonths = runwayMonths < 0 ? 0 : runwayMonths;

    netRunwayMonths = Math.trunc(
      currentClosingBalance / (_avgCashIn - avgCashOut)
    );

    if (currentClosingBalance <= 0) {
      netRunwayMonths = 0;
    } else {
      netRunwayMonths = netRunwayMonths < 0 ? Math.abs(netRunwayMonths) : "INF";
    }

    runwayDateRelativeToCurrentDate = moment()
      .add(runwayMonths, "months")
      .format("MMM YYYY");

    netRunwayDateRelativeToCurrentDate = moment()
      .add(netRunwayMonths, "months")
      .format("MMM YYYY");

    // Average Burn Rate
    const avgBurnRate = cloneDeep(avgBurnRateData);
    avgBurnRate.subTitle = `${baseCurrency} ${formatCurrency(avgCashOut, 2)}`;

    // make cash out positive
    avgBurnRate.data = cashOutOverDateRange.map((val) => -val);
    setAvgBurnRateData(avgBurnRate);

    setRunWay({
      value: runwayMonths,
      months: runwayMonths,
      lastRunwayMonth: runwayDateRelativeToCurrentDate,
      netRunwayVal: netRunwayMonths,
      lastNetRunwayMonth: netRunwayDateRelativeToCurrentDate,
    });

    setCashFlowLegends(legends);
  }, [cashflowReport]);

  const handleRunWayChange = () => {
    setIsChecked(!isChecked);
  };

  const getRunwayTitle = (isChecked: boolean, runway: any) => {
    let title = `<span></span>`;
    if (isChecked) {
      if (runway.netRunwayVal === "INF") {
        title = `<span><strong style="font-size: 24px;" >∞</strong> <span style="font-size: 24px;">months <img style="width: 24px; height: 24px;" src="${ICONS.celebrations}" /></span></span>`;
      } else {
        title = `<span style="font-size: 24px;">${runway.netRunwayVal} months</span>`;
      }
    } else
      title = `<span style="font-size: 24px;">${runway.value} months</span>`;

    return title;

    /**
       * `${
      isChecked
        ? runWay.netRunwayVal === "INF"
          ? `<strong style="font-size: 20px;">∞</strong>`
          : runWay.netRunwayVal
        : runWay.months
    } Months ${runWay.netRunwayVal === "INF" ? "🎉" : ""}`
       */
  };

  useEffect(() => {
    if (cashflowReport.length > 0) {
      const dateValues = cashflowReport.filter(
        (cr: any) => cr.name === "Cashflow Statement"
      );

      setDashboardDateValues(dateValues);
    }
  }, [cashflowReport]);

  const handleDateRangeChange = (
    startDate: Date,
    endDate: Date,
    filteredData: any[]
  ) => {
    const balanceOverDateRange = filteredData[0].data;
    const cashInOverDateRange = filteredData[1].data;
    const cashOutOverDateRange = filteredData[2].data;
    const unreconciledOverDateRange = filteredData[3].data;

    const cashFlow = cloneDeep(cashFlowData);
    cashFlow[0].data = balanceOverDateRange;
    cashFlow[1].data = cashInOverDateRange;
    cashFlow[2].data = cashOutOverDateRange;
    cashFlow[3].data = unreconciledOverDateRange;

    const currentDate = new Date();
    // const currentDateIndex = getCashflowHeader()
    //   .slice(startDate.getMonth(), endDate.getMonth() + 1)
    //   .findIndex((column: string) =>
    //     moment(column).isSame(currentDate, "month")
    //   );

    const datesInRange = getCashflowHeader().filter(
      (date: Date) =>
        moment(date).isSameOrAfter(moment(startDate).startOf("month")) &&
        moment(date).isSameOrBefore(moment(endDate).endOf("month"))
    );

    // Find current month index within filtered range
    const currentDateIndex = datesInRange.findIndex((date: Date) =>
      moment(date).isSame(moment(), "month")
    );

    // Determine closing balance
    let currentClosingBalance;
    if (currentDateIndex !== -1) {
      currentClosingBalance = balanceOverDateRange[currentDateIndex];
    } else {
      currentClosingBalance =
        balanceOverDateRange[balanceOverDateRange.length - 1];
    }

    const totalCashIn = _.sum(cashInOverDateRange);
    const totalCashOut = _.sum(cashOutOverDateRange);
    const totalUnrec = _.sum(unreconciledOverDateRange);
    // const currentClosingBalance =
    //   currentDateIndex !== -1 ? balanceOverDateRange[currentDateIndex] : 0;
    currentClosingBalance =
      balanceOverDateRange[balanceOverDateRange.length - 1];
    const avgCashIn = _.mean(cashInOverDateRange);
    const avgCashOut = Math.abs(_.mean(cashOutOverDateRange));
    
    const legends = cloneDeep(cashflowLegends);
    legends[1].value = `${baseCurrency} ${formatCurrency(totalCashIn || 0, 2)}`;
    legends[2].value = `${baseCurrency} ${formatCurrency(
      totalCashOut || 0,
      2
    )}`;
    legends[0].value = `${baseCurrency} ${formatCurrency(
      currentClosingBalance,
      2
    )}`;
    legends[3].value = `${baseCurrency} ${formatCurrency(totalUnrec, 2)}`;
    setCashFlowLegends(legends);

    const avgCashInUpdated = cloneDeep(avgCashInData);
    avgCashInUpdated.subTitle = `${baseCurrency} ${formatCurrency(
      avgCashIn || 0,
      2
    )}`;
    avgCashInUpdated.data = cashInOverDateRange;
    setAvgCashInData(avgCashInUpdated);

    let runwayMonths = 0;
    let netRunwayMonths: string | number = 0;

    if (currentClosingBalance > 0) {
      runwayMonths = Math.trunc(currentClosingBalance / avgCashOut) || 0;
      netRunwayMonths = Math.trunc(
        currentClosingBalance / (avgCashIn - avgCashOut)
      );
      netRunwayMonths = netRunwayMonths < 0 ? Math.abs(netRunwayMonths) : "INF";
    }

    const runwayDateRelativeToCurrentDate = moment()
      .add(runwayMonths, "months")
      .format("MMM YYYY");

    const netRunwayDateRelativeToCurrentDate = moment()
      .add(typeof netRunwayMonths === "number" ? netRunwayMonths : 0, "months")
      .format("MMM YYYY");

    const avgBurnRate = cloneDeep(avgBurnRateData);
    avgBurnRate.subTitle = `${baseCurrency} ${formatCurrency(avgCashOut, 2)}`;
    avgBurnRate.data = cashOutOverDateRange.map((val: any) => -val);
    setAvgBurnRateData(avgBurnRate);

    setRunWay({
      value: runwayMonths,
      months: runwayMonths,
      lastRunwayMonth: runwayDateRelativeToCurrentDate,
      netRunwayVal: netRunwayMonths,
      lastNetRunwayMonth: netRunwayDateRelativeToCurrentDate,
    });
  };

  return (
    <div style={{ display: "flex" }} className=" pb-6 px-6">
      <div
        className="line-charts-coniner"
        style={{
          minWidth: "60%",
          maxWidth: "60%",
        }}
      >
        {dashboardLoading ? (
          <Placeholder.Graph
            height={450}
            width={window.innerWidth * 0.48}
            className="m-3"
            style={{ borderRadius: 10 }}
            active
          />
        ) : (
          <LineChart
            title="Cash In vs Cash Out"
            subTitle="SUMMARIZED FROM “PAY“ AND “GET PAID”"
            legends={cashflowLegends}
            dataSets={cashFlowData}
            cashflowDateArray={dashboardDateValues}
            cashflowConfigs={cashflowConfigs}
            onDateRangeChange={handleDateRangeChange}
          />
        )}
      </div>
      <div
        className="is-flex is-flex-direction-row "
        style={{
          minWidth: "40%",
          maxWidth: "40%",
          minHeight: "48%",
          maxHeight: "48%",
        }}
      >
        <div
          // className="is-flex is-flex-direction-column"
          style={{
            minWidth: "50%",
            maxWidth: "50%",
          }}
        >
          {dashboardLoading ? (
            <Placeholder.Graph
              height={210}
              width={window.innerWidth * 0.16}
              className="m-3"
              style={{ borderRadius: 10 }}
              active
            />
          ) : (
            <Card
              className="m-2"
              style={{
                minHeight: "48%",
                maxHeight: "48%",
                position: "relative",
              }}
            >
              <CardTitle title="POTENTIAL COST SAVING" />
              <CardSubTitle title={`Coming Soon`} />
              <img
                className="financial-health-image mt-5"
                alt="financial-health"
                src={"/assets/images/financial-health.svg"}
                style={{
                  position: "absolute",
                  bottom: "25%",
                  left: "20%",
                  width: "55%",
                  height: "40%",
                }}
              />
            </Card>
          )}
          {dashboardLoading ? (
            <Placeholder.Graph
              height={210}
              width={window.innerWidth * 0.16}
              className="m-3"
              style={{ borderRadius: 10 }}
              active
            />
          ) : (
            <Card
              className="m-2"
              style={{ minHeight: "48%", maxHeight: "48%" }}
            >
              <div className="runway-switch-contantainer">
                {isChecked ? (
                  <CardTitle title={`NET RUNWAY`} />
                ) : (
                  <CardTitle title="RUNWAY" />
                )}
                {/* 🎉 */}
                <Switch
                  className="runway-switch"
                  height={18}
                  width={35}
                  onChange={handleRunWayChange}
                  checked={isChecked}
                />
              </div>
              <CardSubTitle title={getRunwayTitle(isChecked, runWay)} />

              <ProgressBarCircular
                value={
                  isChecked
                    ? runWay.netRunwayVal === "INF"
                      ? 100
                      : runWay.netRunwayVal
                    : runWay.value
                }
                text={
                  isChecked
                    ? runWay.netRunwayVal === "INF"
                      ? "Profitable"
                      : runWay.lastNetRunwayMonth
                    : runWay.lastRunwayMonth
                }
              />
            </Card>
          )}
        </div>

        <div
          className="is-flex is-flex-direction-column"
          style={{
            minWidth: "50%",
            maxWidth: "50%",
          }}
        >
          {dashboardLoading ? (
            <Placeholder.Graph
              height={210}
              width={window.innerWidth * 0.16}
              className="m-3"
              style={{ borderRadius: 10 }}
              active
            />
          ) : (
            <BarChart data={avgCashInData} isCashIn={true} />
          )}
          {dashboardLoading ? (
            <Placeholder.Graph
              height={210}
              width={window.innerWidth * 0.16}
              className="m-3"
              active
              style={{ borderRadius: 10 }}
            />
          ) : (
            <BarChart data={avgBurnRateData} isCashIn={false} />
          )}
        </div>
      </div>
    </div>
  );
}

export default CompanyDashboard;
