import "./styles.css";
import React from "react";
import { CardSubTitle } from "../CardSubTitle";
import { CardTitle } from "../CardTitles";
import { title } from "process";

interface GraphLegendPropTypes {
  title: string;
  value: string;
  color?: string;
  index?: number;
}

function GraphLegend({ title, value, color, index }: GraphLegendPropTypes) {
  return (
    <div className="graph-legend-container" key={index}>
      <div className="dot-title ">
        <Dot color={color} />
        <CardTitle title={title} />
      </div>
      <CardSubTitle title={value} />
    </div>
  );
}

const Dot = ({ color }: any) => {
  return (
    <span
      style={{ backgroundColor: color, marginRight: "0.2rem", marginTop: 2 }}
      className="dot"
    ></span>
  );
};
export default GraphLegend;
