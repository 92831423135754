import { useState, useRef, useEffect, Fragment } from "react";
import { useResizableColumns } from "../../../../hooks/useResizableColumns";
import { useLocation } from "react-router-dom";
import { Uploader } from "rsuite";
import { FileType } from "rsuite/esm/Uploader";
import styled from "styled-components";
import moment from "moment";
import { appTheme } from "../../../../constants";
import { COLORS } from "../../../../constants/theme";
import { faCheck, faTimes, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { configs } from "../../../../constants";
import { showNotification } from "@mantine/notifications";
import Card from "../../../../common/Card/Card";
import { Select, Button, LoadingOverlay } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useAuth } from "../../../../contexts";
import currencies from "../../../../assets/files/currencies.json";
import { getUserLS } from "../../../../modules/localStorage";
import { InvoiceTypes } from "../../../../constants/globalConstants";
import { filterContacts } from "../../../../repositories/contactRepository";
import _ from "lodash";
const { v4: uuidv4 } = require("uuid");

// Re-run the pipeline

const estimate_sample_bills_data = require("../../../../assets/files/bills_estimates_sample.csv");
const estimate_sample_invoices_data = require("../../../../assets/files/invoices_estimates_sample.csv");

type EstimateInterface = {
  name: string;
  amount: string;
  currency: string;
  startDate: string;
  howOftenDoesThisOccur: string;
  // hasEndDate: boolean;
  endDate: string;
  doesAmountChange: string;
  cashflowCategory: string;
  type: string;
  section: string;
};

const COLUMNS = [
  { label: "Name" },
  { label: "Amount" },
  { label: "Start Date" },
  { label: "Occurence" },
  { label: "Currency" },
  { label: "End Date" },
  // { label: "Has End Date" },
  { label: "Amount Change" },
  { label: "Cashflow Category" },
  { label: "Type" },
  { label: "Section" },
];

interface ContainerProps {
  fileList: any[];
}

enum HowOftenDoesThisOccur {
  Weekly = "Repeats every week",
  Monthly = "Repeats every month",
  Once = "Does Not Repeat",
}

enum DoesAmountChange {
  Yes = "Yes",
  No = "No, it's fixed",
}

function UploadEstimatesCsvBulk({
  isBill,
  setStep,
  onClose,
  fetchForecasts,
}: any) {
  const user = getUserLS();

  const {
    recordsReceivable: _records,
    externalAccounts: _externalAccounts,
    fetchExternalAccounts,
  } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<any>([]);
  const [selectedName, setSelectedName] = useState("");
  const [externalAccounts, setExternalAccounts] =
    useState<any[]>(_externalAccounts);

  const [formValues, setFormValues] = useState<any>({});

  const headerRef = useRef<HTMLTableSectionElement>(null);
  const [fileList, setFileList] = useState<FileType[]>([]);
  const [error, setError] = useState<string | undefined>();
  const [estimateList, setEstimateList] = useState<EstimateInterface[]>([]);
  const [showList, setShowList] = useState<EstimateInterface[]>([]);
  const [dateFormat, setDateFormat] = useState<string>("YYYY-MM-DD");
  const [cashinOptions, setCashinOptions] = useState<any>([]);
  const [cashoutOptions, setCashoutOptions] = useState<any>([]);

  const [isError, setIsError] = useState(false);

  const [widths, handleMouseDown] = useResizableColumns(
    headerRef,
    [100, 100, 100, 100, 100, 100, 100, 100, 100, 100]
  );

  let contactType = InvoiceTypes.Payable ? "Vendor" : "Customer";

  const handleOnClose = () => {
    onClose();
    setEstimateList([]);
    setShowList([]);
    setFormValues({});
  };

  useEffect(() => {
    //getReportLayout
    axios.defaults.withCredentials = true;
    axios.get(configs.urls.BASE_URL + "/getReportLayout").then((res) => {
      if (res.data !== "error") {
        const data = res.data;
        if (data.length === 0) {
        } else {
          let cashIn = data
            .filter((item: any) => item.name === "Receivable")
            .map((item: any) => ({
              label: item.value,
              value: item.value,
            }));
  
          setCashinOptions(cashIn);

          let cashOut = data
            .filter((item: any) => item.name === "Payable")
            .map((item: any) => ({
              label: item.value,
              value: item.value,
            }));
  
          setCashoutOptions(cashOut);
        }
      }
    });
  }, []);

  useEffect(() => {
    setOptions(
      _externalAccounts.map((account: any) => ({
        value: account.displayName,
        label: account.displayName,
        disabled:
          !account.types.includes(contactType) &&
          !account.types.includes("Employee"),
      }))
    );
  }, [_externalAccounts]);

  useEffect(() => {
    if (!selectedName) {
      setExternalAccounts(_externalAccounts);
      return;
    }

    const delayDebounceFn = setTimeout(async () => {
      const filteredContacts = await filterContacts(selectedName);

      setOptions(
        _.uniqBy(filteredContacts, "id").map((account: any) => ({
          value: account.displayName,
          label: account.displayName,
          disabled:
            !account.types.includes(contactType) &&
            !account.types.includes("Employee"),
        }))
      );
      setExternalAccounts((prev) => _.uniqBy(filteredContacts, "id"));
    }, 0);

    return () => clearTimeout(delayDebounceFn);
  }, [selectedName]);

  const resetState = () => {
    if (isError) {
      setError(undefined);
    }
    if (isError && fileList.length > 0) {
      setFileList([]);
    }
  };

  const handleUpload = async (file: FileType[]) => {
    try {
      resetState();
      setLoading(true);
      if (!file || !file.length) return;
      setError(undefined);
      setFileList([file[file.length - 1]]);
    } catch (error) {
      console.log("Error inside handle upload: ", error);
    } finally {
      setLoading(false);
    }
  };

  function parseCSVLine(line: string): string[] {
    const fields = [];
    let currentField = "";
    let insideQuotedString = false;

    for (let i = 0; i < line.length; i++) {
      const char = line[i];

      if (char === "," && !insideQuotedString) {
        fields.push(currentField);
        currentField = "";
      } else if (char === '"') {
        insideQuotedString = !insideQuotedString;
      } else {
        currentField += char;
      }
    }

    fields.push(currentField);

    return fields;
  }

  const padDateComponent = (component: string) => {
    return component.length === 1 ? `0${component}` : component;
  };

  const normalizeDate = (date: string, format: string) => {
    let normalizedDate = date;

    if (format.includes("/")) {
      const parts = date.split("/");
      if (parts.length === 3) {
        const [month, day, year] = parts;
        normalizedDate = `${padDateComponent(month)}/${padDateComponent(
          day
        )}/${year}`;
      } else {
        throw new Error(
          `Invalid date format: '${date}' does not match the expected format MM/DD/YYYY`
        );
      }
    } else if (format.includes("-")) {
      const parts = date.split("-");
      if (parts.length === 3) {
        const [year, month, day] = parts;
        normalizedDate = `${year}-${padDateComponent(month)}-${padDateComponent(
          day
        )}`;
      } else {
        throw new Error(
          `Invalid date format: '${date}' does not match the expected format YYYY-MM-DD`
        );
      }
    }

    return normalizedDate;
  };
  const readCSVFile = async (file: File): Promise<EstimateInterface[]> => {
    const reader = new FileReader();

    return new Promise<EstimateInterface[]>((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        setIsError(true); // Set error state
        reject(new DOMException("Problem parsing input file."));
      };

      reader.onload = () => {
        const text = reader.result as string;
        const lines = text.split(/\r?\n/).filter((line) => line.trim() !== "");

        const errors: string[] = [];
        const dateFields: string[] = [];

        // Step 1: Iterate over all the rows and validate fields and date formats
        lines.slice(1).forEach((line, index) => {
          const fields = parseCSVLine(line);

          const [
            name,
            amount,
            startDate,
            howOftenDoesThisOccur,
            currency,
            endDate,
            doesAmountChange,
            cashflowCategory,
            type,
            section,
          ] = fields;

          // Store date fields for later comparison

          // Validate required fields
          if (
            !name ||
            !amount ||
            !startDate ||
            !howOftenDoesThisOccur ||
            !currency ||
            !endDate ||
            !doesAmountChange ||
            !cashflowCategory ||
            !type ||
            !section
          ) {
            errors.push(`Missing required field(s) on line ${index + 1}`);
          }

          // Validate amount
          const isAmountValid = !isNaN(parseFloat(amount));
          if (!isAmountValid) {
            errors.push(`Invalid amount: '${amount}' on line ${index + 1}`);
          }

          try {
            const normalizedStartDate = normalizeDate(startDate, dateFormat);
            const normalizedEndDate = normalizeDate(endDate, dateFormat);

            dateFields.push(normalizedStartDate, normalizedEndDate);

            // Validate startDate and endDate using the normalized dates
            const isValidStartDate = moment(
              normalizedStartDate,
              dateFormat,
              true
            ).isValid();
            const isValidEndDate = moment(
              normalizedEndDate,
              dateFormat,
              true
            ).isValid();

            if (!isValidStartDate) {
              errors.push(
                `Invalid start date format: '${startDate}' on line ${index + 1}`
              );
            }
            if (!isValidEndDate) {
              errors.push(
                `Invalid end date format: '${endDate}' on line ${index + 1}`
              );
            }

            if (isValidStartDate && isValidEndDate) {
              const startMoment = moment(normalizedStartDate, dateFormat, true);
              const endMoment = moment(normalizedEndDate, dateFormat, true);

              if (startMoment.isAfter(endMoment)) {
                errors.push(
                  `Start date '${startDate}' is greater than end date '${endDate}' on line ${
                    index + 1
                  }`
                );
              }
            }
          } catch (error: any) {
            errors.push(`Error on line ${index + 1}: ${error.message}`);
          }
        });

        if (errors.length > 0) {
          setIsError(true); // Set error state
          reject(new DOMException(errors.join("\n")));
          return;
        }

        // Step 2: Check if all dates are in the same format
        const selectedFormat = dateFormat; // The selected format from the radio buttons
        const consistentFormat = dateFields.every((date) =>
          moment(date, selectedFormat, true).isValid()
        );

        if (!consistentFormat) {
          setIsError(true); // Set error state
          reject(
            new DOMException(
              "Date format is inconsistent or does not match the selected format."
            )
          );
          return;
        }

        // If no errors, proceed with the original logic
        setIsError(false); // Reset error state in case of success

        const newShowList = lines.slice(1).map((line) => {
          const fields = parseCSVLine(line);
          return {
            id: uuidv4(),
            name: fields[0],
            amount: fields[1],
            startDate: fields[2],
            howOftenDoesThisOccur: fields[3],
            currency: fields[4],
            endDate: fields[5],
            doesAmountChange: fields[6],
            cashflowCategory: fields[7],
            type: fields[8],
            section: fields[9],
          };
        });

        const billNamesFromCsv = newShowList.map((bill) => bill.name);

        const updatedOptions = [...options];

        billNamesFromCsv.forEach((billName) => {
          if (!updatedOptions.some((option) => option.value === billName)) {
            updatedOptions.push({ value: billName, label: billName });
          }
        });

        setOptions(updatedOptions);

        setShowList((prevShowList) => {
          const updatedShowList = [...prevShowList, ...newShowList];

          const initialValues = updatedShowList.reduce(
            (acc: any, bill: any) => {
              acc[bill.id] = {
                name: bill.name,
                amount: bill.amount,
                currency: bill.currency,
                // startDate: bill.startDate,
                startDate: moment(bill.startDate, dateFormat).format(
                  "YYYY-MM-DD"
                ),
                howOftenDoesThisOccur: bill.howOftenDoesThisOccur,
                // endDate: bill.endDate,
                endDate: moment(bill.endDate, dateFormat).format("YYYY-MM-DD"),
                doesAmountChange: bill.doesAmountChange,
                cashflowCategory: bill.cashflowCategory,
                type: bill.type,
                section: bill.section,
              };
              return acc;
            },
            {}
          );

          setFormValues(initialValues);

          return updatedShowList;
        });

        resolve(newShowList);
      };

      reader.readAsText(file);
    });
  };

  const onSave = async () => {
    try {
      const estimatesData = [];
      let formData = formValues;
      for (const estimate in formData) {
        estimatesData.push(formData[estimate]);
      }
      setLoading(true);
      axios.defaults.withCredentials = true;
      const response = await axios.post(
        configs.urls.BASE_URL + "/financial_projection/bulkSave",
        { estimates: estimatesData }
      );

      if (response.status === 200) {
        showNotification({
          color: "teal",
          message: "Records uploaded successfully.",
          icon: <FontAwesomeIcon icon={faCheck} />,
          autoClose: 2000,
        });
        setStep(0);
        handleOnClose();
        fetchForecasts();
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const onContinue = async () => {
    try {
      setLoading(true);
      const estimates = await readCSVFile(fileList[0].blobFile as File);

      setError(undefined);
      setEstimateList(estimates);
      setLoading(false);
    } catch (error: any) {
      let errorMessage =
        error?.message ||
        "Invalid file format. Please upload a valid estimates file.";

      setError(errorMessage);
      setLoading(false);
      setFileList([]);
    }
  };

  const handleChange = (billId: any, field: any, value: any) => {
    setFormValues((prev: any) => ({
      ...formValues,
      [billId]: {
        ...prev[billId],
        [field]: value,
      },
    }));
  };

  return (
    <Container fileList={fileList}>
      {!isError && showList.length > 0 ? (
        <Fragment>
          <div
            style={{
              position: "relative",
            }}
          >
            <div className="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center"></div>

            <Card
              className="table-container mb-3"
              style={{
                position: "relative",
              }}
            >
              <div
                className="table-container custom-scrollbar mb-3"
                style={{
                  height: "calc(100vh - 280px)",
                  overflowY: "scroll",
                }}
              >
                <table
                  style={{ borderTop: "1px solid #e0e0e0" }}
                  className="table is-bordered is-striped is-hoverable is-fullwidth is-relative "
                >
                  <thead>
                    <tr
                      style={{
                        fontWeight: "700",
                        position: "sticky",
                        top: 0,
                        backgroundColor: "white",
                        zIndex: 9,
                      }}
                    >
                      <TableHeader>
                        {isBill ? "Vendor or Employee Name" : "Customer Name"}{" "}
                      </TableHeader>
                      <TableHeader>Amount</TableHeader>
                      <TableHeader>Start Date</TableHeader>
                      <TableHeader>Occurence</TableHeader>
                      <TableHeader>Currency</TableHeader>
                      <TableHeader>End Date</TableHeader>
                      <TableHeader>Amount Change</TableHeader>
                      <TableHeader>Cashflow Category</TableHeader>
                      <TableHeader>Type</TableHeader>
                      <TableHeader>Section</TableHeader>
                    </tr>
                  </thead>
                  <tbody>
                    {showList.map((bill: any) => {
                      return (
                        <tr key={bill.id}>
                          <TableData className="p-3">
                            <Select
                              name="name"
                              defaultValue={"AED"}
                              value={formValues[bill.id]?.name}
                              onChange={(value: string) => {
                                setFormValues((prev: any) => ({
                                  ...formValues,
                                  [bill.id]: {
                                    ...prev[bill.id],
                                    ["name"]: value,
                                  },
                                }));
                              }}
                              styles={{
                                input: {
                                  minWidth: "200px !important",
                                  marginTop: 4,
                                  fontWeight: "bold",
                                  fontSize: "14px !important",
                                },
                              }}
                              data={options} // This is the updated options array
                              searchable
                              maxDropdownHeight={400}
                              nothingFound="Nothing here"
                              filter={(value: any, item: any) =>
                                item.label
                                  .toLowerCase()
                                  .includes(value.toLowerCase().trim()) ||
                                item.value
                                  .toLowerCase()
                                  .includes(value.toLowerCase().trim())
                              }
                            />
                          </TableData>
                          <TableData className="p-3">
                            <Input
                              name="amount"
                              value={formValues[bill.id]?.amount}
                              onChange={(e) =>
                                handleChange(bill.id, "amount", e.target.value)
                              }
                            />
                          </TableData>
                          <TableData className="p-3">
                            <CustomDatePicker
                              name="startDate"
                              clearable={false}
                              styles={{
                                input: {
                                  minWidth: "130px !important",
                                  border: "none",
                                  fontWeight: "bolder",
                                  fontSize: "14px !important",
                                },
                              }}
                              value={new Date(formValues[bill.id]?.startDate)}
                              onChange={(date: any) => {
                                handleChange(
                                  bill.id,
                                  "startDate",
                                  moment(date).format("YYYY-MM-DD")
                                );
                              }}
                            />
                          </TableData>
                          <TableData className="p-3">
                            <Select
                              styles={{
                                input: {
                                  minWidth: "200px !important",
                                  marginTop: 4,
                                  fontWeight: "bold",
                                  fontSize: "14px !important",
                                },
                              }}
                              name="howOftenDoesThisOccur"
                              value={formValues[bill.id]?.howOftenDoesThisOccur}
                              data={[
                                {
                                  value: HowOftenDoesThisOccur.Weekly,
                                  label: HowOftenDoesThisOccur.Weekly,
                                },
                                {
                                  value: HowOftenDoesThisOccur.Monthly,
                                  label: HowOftenDoesThisOccur.Monthly,
                                },
                                {
                                  value: HowOftenDoesThisOccur.Once,
                                  label: HowOftenDoesThisOccur.Once,
                                },
                              ]}
                              onChange={(value: string) => {
                                handleChange(
                                  bill.id,
                                  "howOftenDoesThisOccur",
                                  value
                                );
                              }}
                            />
                          </TableData>
                          <TableData>
                            <Select
                              name="currency"
                              defaultValue={"AED"}
                              value={formValues[bill.id]?.currency}
                              onChange={(value: string) => {
                                setFormValues((prev: any) => ({
                                  ...formValues,
                                  [bill.id]: {
                                    ...prev[bill.id],
                                    ["currency"]: value,
                                  },
                                }));
                              }}
                              styles={{
                                input: {
                                  marginTop: 4,
                                  fontWeight: "bold",
                                  fontSize: "14px !important",
                                },
                              }}
                              data={currencies}
                              searchable
                              maxDropdownHeight={400}
                              nothingFound="Nothing here"
                              filter={(value, item: any) =>
                                item.label
                                  .toLowerCase()
                                  .includes(value.toLowerCase().trim()) ||
                                item.value
                                  .toLowerCase()
                                  .includes(value.toLowerCase().trim())
                              }
                            />
                          </TableData>
                          <TableData className="p-3">
                            <CustomDatePicker
                              name="endDate"
                              clearable={false}
                              styles={{
                                input: {
                                  minWidth: "130px !important",
                                  border: "none",
                                  fontWeight: "bolder",
                                  fontSize: "14px !important",
                                },
                              }}
                              value={new Date(formValues[bill.id]?.endDate)}
                              onChange={(date: any) => {
                                handleChange(
                                  bill.id,
                                  "endDate",
                                  moment(date).format("YYYY-MM-DD")
                                );
                              }}
                            />
                          </TableData>
                          <TableData className="p-3">
                            <Select
                              styles={{
                                input: {
                                  minWidth: "130px !important",
                                  marginTop: 4,
                                  fontWeight: "bold",
                                  fontSize: "14px !important",
                                },
                              }}
                              name="doesAmountChange"
                              value={formValues[bill.id]?.doesAmountChange}
                              data={[
                                {
                                  value: DoesAmountChange.Yes,
                                  label: DoesAmountChange.Yes,
                                },
                                {
                                  value: DoesAmountChange.No,
                                  label: DoesAmountChange.No,
                                },
                              ]}
                              onChange={(value: string) => {
                                handleChange(
                                  bill.id,
                                  "doesAmountChange",
                                  value
                                );
                              }}
                            />
                          </TableData>
                          <TableData className="p-3">
                            <Select
                              styles={{
                                input: {
                                  minWidth: "130px !important",
                                  marginTop: 4,
                                  fontWeight: "bold",
                                  fontSize: "14px !important",
                                },
                              }}
                              name="cashflowCategory"
                              value={formValues[bill.id]?.cashflowCategory}
                              data={isBill ? cashoutOptions : cashinOptions}
                              onChange={(value: string) => {
                                handleChange(
                                  bill.id,
                                  "cashflowCategory",
                                  value
                                );
                              }}
                            />
                          </TableData>
                          <TableData className="p-3">
                            <Input
                              readOnly={true}
                              name="type"
                              value={formValues[bill.id]?.type}
                              onChange={(e) =>
                                handleChange(bill.id, "type", e.target.value)
                              }
                            />
                          </TableData>
                          <TableData className="p-3">
                            <Input
                              readOnly={true}
                              name="section"
                              value={formValues[bill.id]?.section}
                              onChange={(e) =>
                                handleChange(bill.id, "section", e.target.value)
                              }
                            />
                          </TableData>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Card>
          </div>
          <button
            onClick={onSave}
            style={{
              backgroundColor: COLORS.greenBlue,
              color: COLORS.white,
              width: "15rem",
            }}
            className={`button mv-6 is-bold is-small ${
              loading ? "is-loading" : ""
            }`}
          >
            Done
          </button>
        </Fragment>
      ) : (
        <Fragment>
          <Uploader
            action="//jsonplaceholder.typicode.com/posts/"
            accept=".csv"
            multiple={false}
            draggable
            autoUpload={false}
            listType="picture-text"
            onChange={handleUpload}
            fileList={fileList}
            removable={true}
          >
            <CardButton
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                padding: "100px",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faUpload}
                  color={appTheme.COLORS.secondary}
                  size="3x"
                />
              </div>
              <div>
                <h5
                  style={{
                    color: appTheme.COLORS.secondary,
                    marginTop: "3%",
                  }}
                >
                  Drag and drop a CSV file here
                </h5>
              </div>
            </CardButton>
          </Uploader>
          {error ? <p style={{ color: "red" }}>{error}</p> : null}

          {fileList.length > 0 ? (
            <div>
              <ChooseDateFormat>
                {/* radio choices */}
                <p>
                  <b>Choose date format</b>
                </p>
                <div className="radio-group">
                  <input
                    type="radio"
                    id="yyyy-mm-dd"
                    name="date-format"
                    value="YYYY-MM-DD"
                    checked={dateFormat === "YYYY-MM-DD"}
                    onChange={(e) => setDateFormat(e.target.value)}
                  />
                  <label htmlFor="yyyy-mm-dd">YYYY-MM-DD</label>
                </div>
                <div className="radio-group">
                  <input
                    type="radio"
                    id="mm-dd-yyyy"
                    name="date-format"
                    value="MM-DD-YYYY"
                    checked={dateFormat === "MM-DD-YYYY"}
                    onChange={(e) => setDateFormat(e.target.value)}
                  />
                  <label htmlFor="mm-dd-yyyy">MM-DD-YYYY</label>
                </div>

                <div className="radio-group">
                  <input
                    type="radio"
                    id="mm/dd/yyyy"
                    name="date-format"
                    value="MM/DD/YYYY"
                    checked={dateFormat === "MM/DD/YYYY"}
                    onChange={(e) => setDateFormat(e.target.value)}
                  />
                  <label htmlFor="mm/dd/yyyy">MM/DD/YYYY</label>
                </div>
                <div className="radio-group">
                  <input
                    type="radio"
                    id="dd/mm/yyyy"
                    name="date-format"
                    value="DD/MM/YYYY"
                    checked={dateFormat === "DD/MM/YYYY"}
                    onChange={(e) => setDateFormat(e.target.value)}
                  />
                  <label htmlFor="dd/mm/yyyy">DD/MM/YYYY</label>
                </div>
              </ChooseDateFormat>
              <button
                onClick={onContinue}
                style={{
                  backgroundColor: COLORS.greenBlue,
                  color: COLORS.white,
                  width: "15rem",
                }}
                className={`button mv-6 is-bold is-small ${
                  loading ? "is-loading" : ""
                }`}
              >
                Continue
              </button>
            </div>
          ) : (
            <div className="mt-5">
              Need a Sample CSV File? Download{" "}
              <a
                href={
                  isBill
                    ? estimate_sample_bills_data
                    : estimate_sample_invoices_data
                }
                download
              >
                here
              </a>
            </div>
          )}
        </Fragment>
      )}
    </Container>
  );
}

export default UploadEstimatesCsvBulk;

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
  width: ${(props) => (props.fileList.length > 0 ? "100vw" : "100%")};
`;

const CardButton = styled.button`
  border: 1px solid #707070;
  border-radius: 3px;
  background-color: white;
  color: #707070;
  font-size: 14px;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: #f5f5f5;
  }

  &:active {
    background-color: #e5e5e5;
  }
`;

const ChooseDateFormat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 15px;

  .radio-group {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }

  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid ${COLORS.greenBlue};
    border-radius: 50%;
    outline: none;
    transition: all 0.3s ease;
  }

  input[type="radio"]:checked {
    background: ${COLORS.greenBlue};
    /* dot in */
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #fff;

      transition: all 0.3s ease;
      opacity: 1;
    }
  }

  > p {
    margin-bottom: 10px;
    margin-top: 10px;
  }
`;

const TableData = styled.td`
  font-size: 14px;
  text-align: left;
  /* :sec-child {
    min-width: 120px;
  } */
`;

const TableHeader = styled.th`
  font-weight: 700;
  font-size: 14px;
  color: ${COLORS.brownGrey};
  text-align: left;
  height: 20px;
  vertical-align: middle;
  /* :first-child {
    min-width: 120px;
  } */
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  border: none;
  outline-width: 0;
  border-bottom: 1px solid #707070;
  font-weight: 600;
  font-size: 14px;

  @media screen and (max-width: 1600px) {
    font-size: 20px;
  }
`;

export const CustomDatePicker = styled(DatePicker)`
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #707070;

  .mantine-DatePicker-dropdown {
    width: 455px !important;
  }
`;
