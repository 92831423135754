import _ from "lodash";
import { useEffect, useState } from "react";
import { INITIAL_CONFIGS } from "../constants/dummyData";
import {
  CashflowConfigs,
  CashflowRowType,
  PeriodFilter,
} from "../constants/globalConstants";
import {
  getCashflowConfigs,
  getCashflowReport,
  updateCashflowConfigs,
} from "../pages/Cashflow/repository/cashflowConfigs";
import { configs } from "../constants";
import axios from "axios";
import { io } from "socket.io-client";
import {
  addNotification,
  notify,
  updateNotification,
} from "../modules/showNotification";
import { getFormattedTime } from "../modules/time";
import { CashflowTitles } from "../pages/Cashflow/constants";
import { ICashflowScenario } from "../constants/types";
import { getAllScenarios } from "../repositories/cashflowScenario";
import moment from "moment";

const sync_url =
  process.env.REACT_APP_SOCKET_URL_SYNC || "http://localhost:5000";
const sync_server = io(sync_url, {
  path: process.env.REACT_APP_SOCKET_URL_SYNC ? "/sync/socket.io" : undefined,
});

export function useCashflow() {
  const [fullRowData, setFullRowData] = useState<any[]>([]);
  const [reportRows, setReportRows] = useState<any[]>([]);
  const [dashboardData, setDashboardData] = useState<any[]>([]);
  const [selectedScenario, setSelectedScenario] = useState<ICashflowScenario>(
    {} as ICashflowScenario
  );

  const [allScenarios, setAllScenarios] = useState<ICashflowScenario[]>([]);
  const [compareTo, setCompareTo] = useState<any>(); // cashflow version
  // const [reportRowsOriginal, setReportRowsOriginal] = useState<any[]>([]);

  const [cashflowConfigs, setCashflowConfigs] = useState<CashflowConfigs>(
    {} as CashflowConfigs
  );

  const [reportLoading, setReportLoading] = useState(false);
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [configsLoading, setConfigsLoading] = useState(false);
  const [obErrorMessage, setObErrorMessage] = useState("");
  const [obMessage, setObMessage] = useState("");
  // const [currentOrg, setCurrentOrg] = useState(undefined);
  //message
  const [dontRefetch, setDontRefetch] = useState(false);

  const fetchCashflowConfigs = async () => {
    setConfigsLoading(true);
    // setReportLoading(true);
    const { data, success } = await getCashflowConfigs();
    setConfigsLoading(false);
    // setReportLoading(false);
    // console.log("reportData", reportData);

    if (success) {
      setCashflowConfigs(data);
    }
  };

  // useEffect(() => {
  //   (async () => {
  //     const orgResponse = await axios.get(
  //       configs.urls.BASE_URL + "/getOrganization",
  //       {
  //         withCredentials: true,
  //       }
  //     );
  //     let orgId = orgResponse.data.organization.id;
  //     console.log("orgId", orgId);

  //     setCurrentOrg(orgId);
  //   })();
  // }, []);

  // useEffect(() => {
  //   console.log("dontRefetch .... ", dontRefetch);
  // }, [dontRefetch]);

  useEffect(() => {
    sync_server.on("calculate_cashflow", async (data: any) => {
      const orgResponse = await axios.get(
        configs.urls.BASE_URL + "/getOrganization",
        {
          withCredentials: true,
        }
      );
      let orgId = orgResponse.data.organization.id;

      if (data?.organizationId === orgId) {
        if (data?.type === "start") {
          addNotification({
            title: "Cashflow Report",
            message: "Updating cashflow...",
            id: "cashflow_report",
            isLoading: true,
            closable: false,
          });
        } else {
          getCashflowReportNewV2();
          if (!data?.success) {
            updateNotification({
              id: "cashflow_report",
              message: "There is some error while updating cashflow",
              title: "Error",
              error: true,
            });
          }
        }
      }
    });
  }, []);

  const transformCashflowRows = (rows: any[]) => {
    // console.log("rows", rows);
    // group rows where cashflowCategory is same
    let cashInCategories = rows.filter(
      (row: any) =>
        row.rowType === CashflowRowType.RecordsGroup &&
        row.type === "Receivable"
    );
    // console.log("cashInCategories .... ", cashInCategories);

    // sort by name
    cashInCategories = _.sortBy(cashInCategories, (row: any) => {
      return row.name;
    });

    cashInCategories = cashInCategories.map((categoryRow: any) => {
      let cashInGroupedRows = rows.filter(
        (row: any) =>
          row.rowType === CashflowRowType.Record &&
          row.cashflowCategory === categoryRow.name &&
          row.type === "Receivable"
      );

      // sort by name
      cashInGroupedRows = _.sortBy(cashInGroupedRows, (row: any) => {
        return row.name;
      });
      categoryRow.children = cashInGroupedRows;
      return categoryRow;
    });

    // add cashInGroupedRows as children to TotalCashIn to update rows
    let totalCashInRow = rows.find(
      (row: any) => row.rowType === CashflowRowType.CashInTotal
    );

    if (totalCashInRow) {
      totalCashInRow.children = cashInCategories;
    }

    let cashOutCategories = rows.filter(
      (row: any) =>
        row.rowType === CashflowRowType.RecordsGroup && row.type === "Payable"
    );

    //sort by name
    cashOutCategories = _.sortBy(cashOutCategories, (row: any) => {
      return row.name;
    });

    cashOutCategories = cashOutCategories.map((categoryRow: any) => {
      let cashOutGroupedRows = rows.filter(
        (row: any) =>
          row.rowType === CashflowRowType.Record &&
          row.cashflowCategory === categoryRow.name &&
          row.type === "Payable"
      );

      // sort by name
      cashOutGroupedRows = _.sortBy(cashOutGroupedRows, (row: any) => {
        return row.name;
      });

      categoryRow.children = cashOutGroupedRows;
      return categoryRow;
    });

    let totalCashOutRow = rows.find(
      (row: any) => row.rowType === CashflowRowType.CashOutTotal
    );

    if (totalCashOutRow) {
      totalCashOutRow.children = cashOutCategories;
    }

    let resultantRows = rows.filter(
      (row: any) =>
        row.rowType === CashflowRowType.OpeningBalance ||
        row.rowType === CashflowRowType.ClosingBalance ||
        row.rowType === CashflowRowType.NetCash ||
        row.rowType === CashflowRowType.OpeningBalanceTotal ||
        row.rowType === CashflowRowType.Unreconciled ||
        row.name === CashflowTitles.CashflowStatement
    );

    resultantRows.push(totalCashInRow);
    resultantRows.push(totalCashOutRow);

    // console.log("resultantRows", resultantRows);
    // remove undefined values
    resultantRows = resultantRows.filter((row: any) => row);

    return resultantRows;
  };

  const applyFilters = (
    rows: TransformedRow[],
    cashlowConfig: CashflowConfigs,
    expandables: boolean
  ) => {
    const startDate = cashlowConfig?.startDate;
    const endDate = cashlowConfig?.endDate;
    const periodFilter = cashlowConfig?.periodFilter;

    if (!startDate || !endDate || !periodFilter) {
      console.error("Invalid configuration for date filtering.");
      return rows;
    }

    const dateRange: string[] = [];

    if (periodFilter === PeriodFilter.Daily) {
      let currentDate = moment(startDate);
      while (currentDate.isSameOrBefore(endDate)) {
        dateRange.push(currentDate.format("YYYY-MM-DD"));
        currentDate.add(1, "day");
      }
    } else if (periodFilter === PeriodFilter.Monthly) {
      let currentDate = moment(startDate).startOf("month");
      while (currentDate.isSameOrBefore(endDate)) {
        dateRange.push(currentDate.format("YYYY-MM-DD"));
        currentDate.add(1, "month");
      }
    } else if (periodFilter === PeriodFilter.Weekly) {
      let currentDate = moment(startDate).startOf("week");
      while (currentDate.isSameOrBefore(endDate)) {
        dateRange.push(currentDate.format("YYYY-MM-DD"));
        currentDate.add(1, "week");
      }
    }

    if (expandables) {
      return rows.map((row) => {
        if (row.name === "Cashflow Statement") {
          return {
            ...row,
            values: row.values.filter((v: any) => dateRange.includes(v)),
          };
        } else if (
          row.name === "Total Cash In" ||
          row.name === "Total Cash Out"
        ) {
          const childrenData = row.children
            ? row.children.map((child) => ({
                ...child,
                values: child.values.filter((v: any) =>
                  dateRange.includes(v.date)
                ),
                children: child.children
                  ? child.children.map((grandChild: any) => ({
                      ...grandChild,
                      values: grandChild.values.filter((v: any) =>
                        dateRange.includes(v.date)
                      ),
                    }))
                  : [],
              }))
            : [];

          const updatedRow = {
            ...row,
            values: row.values.filter((v: any) => dateRange.includes(v.date)),
            children: [...childrenData],
          };

          return updatedRow;
        } else {
          return {
            ...row,
            values: row.values.filter((v: any) => dateRange.includes(v.date)),
          };
        }
      });
    }

    return rows.map((row) => {
      if (row.name === "Cashflow Statement") {
        return {
          ...row,
          values: row.values.filter((v: any) => dateRange.includes(v)),
        };
      } else {
        return {
          ...row,
          values: row.values.filter((v: any) => dateRange.includes(v.date)),
        };
      }
    });
  };

  const transformCashflowRowsV3 = (
    data: any,
    scenario: ICashflowScenario,
    filter: boolean
  ) => {
    const transformedRows: RowType[] = [];
    const uniqueDates = new Set<string>();
    data.forEach((item: any) => {
      const row = item.generate_dynamic_cashflow_paginate;
      transformedRows.push(row);

      Object.keys(row).forEach((key) => {
        if (
          ![
            "id",
            "name",
            "rowType",
            "cashflowCategory",
            "cashflowType",
            "disabledForCashflow",
            "referenceId",
          ].includes(key)
        ) {
          uniqueDates.add(key);
        }
      });
    });

    const sortedDates = Array.from(uniqueDates).sort();

    let filledValues = fillMissingValues(
      transformedRows,
      Array.from(uniqueDates),
      new Date()
    );

    filledValues.push({
      name: "Cashflow Statement",
      values: sortedDates,
      isOpen: false,
    });

    // console.log("Filled values .... ", filledValues)

    if (!filter) {
      const finalRows = transformCashflowRows(filledValues);
      return finalRows;
    }

    const filteredRows = applyFilters(
      filledValues,
      scenario?.cashflowConfigs![0],
      false
    );
    const finalRows = transformCashflowRows(filteredRows);
    return finalRows;
  };

  const getCashflowReportNew = async () => {
    setReportLoading(true);
    const { data } = await axios.post(
      configs.urls.BASE_URL + "/cashflow_scenario/get",
      {},
      {
        withCredentials: true,
      }
    );
    setReportLoading(false);

    if (data.success) {
      setSelectedScenario(
        (data.response?.scenario as ICashflowScenario) ||
          ({} as ICashflowScenario)
      );
      if (data.response?.rows?.length > 0) {
        let rows = data.response.rows;

        let resultantRows = transformCashflowRows(rows);

        // setReportRows(resultantRows);

        updateNotification({
          id: "cashflow_report",
          message: "Cashflow saved!",
          title: "Success",
          error: false,
        });
      }
    }
  };

  const getCashflowReportNewV2 = async () => {
    setReportLoading(true);
    const { data } = await axios.post(
      configs.urls.BASE_URL + "/cashflow_scenario/getDynamicQuery",
      {},
      {
        withCredentials: true,
      }
    );
    setReportLoading(false);

    if (data.success) {
      setSelectedScenario(
        (data.response?.scenario as ICashflowScenario) ||
          ({} as ICashflowScenario)
      );
      if (data.response?.result?.length > 0) {
        let rows = data.response?.result;

        // console.log("data.response?.result ... ", data.response?.result);

        // console.log("OG data ... ", rows);

        let unFilteredData = transformCashflowRowsV3(
          rows,
          data.response?.scenario,
          false
        );

        setFullRowData(unFilteredData);

        let filteredData = transformCashflowRowsV3(
          rows,
          data.response?.scenario,
          true
        );

        setReportRows(filteredData);

        updateNotification({
          id: "cashflow_report",
          message: "Cashflow saved!",
          title: "Success",
          error: false,
        });
      }
    }
  };

  const getDashboardData = async () => {
    setDashboardLoading(true);
    const { data } = await axios.post(
      configs.urls.BASE_URL + "/cashflow_scenario/getDashboardMonthlyData",
      {},
      {
        withCredentials: true,
      }
    );
    setDashboardLoading(false);

    if (data.success) {
      if (data.response?.result?.length > 0) {
        let rows = data.response?.result;

        let unFilteredData = transformCashflowRowsV3(
          rows,
          data.response?.scenario,
          false
        );

        setDashboardData(unFilteredData);
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (
        !_.isEmpty(cashflowConfigs) &&
        !_.isEqual(cashflowConfigs, INITIAL_CONFIGS)
      ) {
        await updateCashflowConfigs(cashflowConfigs).then((data: any) => {
          const prevConfigs = data?.data;

          setDontRefetch(true);

          // if (!_.isEqual(cashflowConfigs, prevConfigs)) {
          //   // getCashflowReportNew();
          //   getCashflowReportNewV2();
          // }

          if (
            cashflowConfigs.startDate !== prevConfigs?.startDate ||
            cashflowConfigs.endDate !== prevConfigs?.endDate
          ) {
            const currentDashboardRows = _.cloneDeep(fullRowData);
            // console.log("Intial rows ... ", currentDashboardRows);

            const filteredRows = applyFilters(
              currentDashboardRows,
              cashflowConfigs,
              true
            );

            // console.log("after filtering filteredRows ... ", filteredRows);

            setReportRows(filteredRows);
          }

          if (cashflowConfigs.periodFilter !== prevConfigs?.periodFilter) {
            getCashflowReportNewV2();
          }
        });
      }
    })();
  }, [cashflowConfigs, fullRowData]);

  const fetchScenarios = async () => {
    const { success, data } = await getAllScenarios();
    if (success) {
      // console.log("refetching and got", data);
      let cashflowScenarios = data.map((item: any) => ({
        name: item.name,
        id: item.id,
        createdAt: item.createdAt,
        isActive: item.isActive,
        description: item.description,
        isDefault: item.isDefault,
        categoriesTemplates: item.categoriesTemplates,
        snapshots: item.snapshots,
      }));
      setAllScenarios(cashflowScenarios);
    }
  };

  useEffect(() => {
    fetchScenarios();
  }, []);

  type RowType = {
    name: string;
    rowType: string;
    [date: string]: string | null;
  };

  type TransformedRow = {
    name: string;
    values: any[];
    isOpen: boolean;
    rowType?: string;
    cashflowCategory?: string | null;
    id?: string | null;
    referenceId?: string | null;
    type?: string;
    disabled?: boolean;
    children?: any[];
  };

  const fillMissingValues = (
    rows: RowType[],
    dateRange: string[],
    currentDate: Date
  ): TransformedRow[] => {
    let resultantRows: TransformedRow[] = [];

    const sortedRows = _.sortBy(rows, "rowType").reverse();

    rows.forEach((row) => {
      const tempRow: { date: string; value: string; source: string }[] = [];
      let prevCb = "0";
      let prevOb = "0";
      let prevObSingle: string | null = null;

      dateRange.forEach((date, index) => {
        let value = row[date];
        let source = "None";

        if (value !== null) {
          // When value exists, update previous balances based on row type
          if (row.rowType === "OpeningBalanceTotal") prevOb = value;
          if (row.rowType === "ClosingBalance") prevCb = value;
          if (row.rowType === "OpeningBalance") prevObSingle = value;

          source = "Original";
        } else {
          // Handle missing values
          if (row.rowType === "OpeningBalanceTotal") {
            value = prevOb;
            source = "Calculated";
          } else if (
            row.rowType === "OpeningBalance" &&
            new Date(date) < currentDate
          ) {
            value = prevObSingle ?? "0";
            source = "Calculated";
          } else {
            value = "0";
          }
        }

        tempRow.push({ date, value: value || "0", source });
      });

      // Determine row type for Receivable or Payable
      const type = row.cashflowType === "CashIn" ? "Receivable" : "Payable";

      resultantRows.push({
        name: row.name,
        values: tempRow,
        isOpen: false,
        rowType: row.rowType,
        cashflowCategory: row.cashflowCategory || null,
        id: row.id || null,
        referenceId: row.referenceId || null,
        type,
        disabled: row.disabledForCashflow ? true : false,
      });
    });

    rows
      .filter((row) => row.rowType === "ClosingBalance")
      .forEach((row) => {
        const changedRow: { date: string; value: string; source: string }[] =
          [];
        dateRange.forEach((date, index) => {
          let value = row[date];
          let source = "None";

          if (value === null) {
            value = "0";
            // in case closing balance value is not available, take it as same from next opening balance
            const totalOpeningBalanceRow = resultantRows.find(
              (row) => row.rowType === "OpeningBalanceTotal"
            );

            if (totalOpeningBalanceRow) {
              if (index === dateRange.length - 1) {
                // Note: in case of last closing balance value (where it does not exist),
                // we need to put same opening balance as that date (last date)
                const lastDate = dateRange[index];
                const currentOB = totalOpeningBalanceRow.values.find(
                  (v) => v.date === lastDate
                );

                if (currentOB) {
                  value = currentOB?.value;
                }
              } else {
                const nextDate = dateRange[index + 1];
                if (nextDate) {
                  const nextOB = totalOpeningBalanceRow.values.find(
                    (v) => v.date === nextDate
                  );
                  if (nextOB) {
                    value = nextOB?.value;
                  }
                }
              }
            }
          }

          changedRow.push({ date, value: value || "0", source });
        });

        resultantRows = resultantRows.map((row) => {
          if (row.rowType === "ClosingBalance") {
            return {
              ...row,
              values: changedRow,
            };
          } else return row;
        });
      });

    return resultantRows;
  };

  return {
    reportRows,
    setReportRows,
    // fetchCashflowReport: getCashflowReportNew,
    fetchCashflowReport: getCashflowReportNewV2,
    cashflowConfigs,
    setCashflowConfigs,
    reportLoading,
    setReportLoading,
    configsLoading,
    setConfigsLoading,
    obErrorMessage,
    setObErrorMessage,
    obMessage,
    setObMessage,
    fetchCashflowConfigs,
    selectedScenario,
    allScenarios,
    fetchScenarios,
    dashboardData,
    getDashboardData,
    dashboardLoading,
    compareTo,
    setCompareTo,
  };
}
