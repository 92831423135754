import moment from "moment";
import { DisbursementMethod } from "./types";

export enum Tier {
  Demo = "demo",
  Starter = "starter",
  TwoFreeStarter = "two-free-starter",
  FiveFreeStarter = "five-free-starter",
  Pro = "pro",
  ProPlus = "pro+",
  Ten = "ten",
  TwentyFive = "twenty-five",
  Fifty = "fifty",
  Thirty = "thirty",
  TwoHundred = "two-hundred",
}

export enum BillingPeriod {
  Monthly = "monthly",
  Yearly = "yearly",
}

// export const PLANS = [
//   {
//     name: "Demo",
//     tier: Tier.Demo,
//     price: 0,
//     capabilities: {
//       noOfScenarios: 2,
//       noOfSyncsPerDay: 4, // on demand syncs
//       scenarioDateRange: 1, // +1 years from signup date
//     },
//   },
//   {
//     name: "Starter",
//     tier: Tier.Starter,
//     price: 59,
//     apiId: "price_1NcmPGF93NLsr0vsdcRJuYgQ",
//     period: BillingPeriod.Monthly,
//     capabilities: {
//       noOfScenarios: 10, // 10
//       noOfSyncsPerDay: 4, // on demand syncs
//       scenarioDateRange: 3, // +3 years from signup date
//     },
//   },
//   {
//     name: "Pro",
//     tier: Tier.Pro,
//     price: 99,
//     apiId: "price_1NcmPqF93NLsr0vsPqVN7Mz1",
//     period: BillingPeriod.Monthly,
//     capabilities: {
//       noOfScenarios: 10,
//       noOfSyncsPerDay: 10, // on demand syncs
//       scenarioDateRange: 10, // +10 years from signup date
//     },
//   },
//   {
//     name: "Pro+",
//     tier: Tier.ProPlus,
//     price: 199,
//     apiId: "price_1NcmQ5F93NLsr0vsjozGYOoa",
//     period: BillingPeriod.Monthly,
//     capabilities: {
//       noOfScenarios: 50,
//       noOfSyncsPerDay: 10, // on demand syncs
//       scenarioDateRange: 10, // +10 years from signup date
//     },
//   },
//   {
//     name: "Starter",
//     tier: Tier.Starter,
//     price: 570,
//     apiId: "price_1NcmRDF93NLsr0vsgaay2jJL",
//     period: BillingPeriod.Yearly,
//     capabilities: {
//       noOfScenarios: 3,
//       noOfSyncsPerDay: 10, // on demand syncs
//       scenarioDateRange: 3, // +3 years from signup date
//     },
//   },
//   {
//     name: "Pro",
//     tier: Tier.Pro,
//     price: 950,
//     apiId: "price_1NcmS7F93NLsr0vsqcSp6Dk4",
//     period: BillingPeriod.Yearly,
//     capabilities: {
//       noOfScenarios: 10,
//       noOfSyncsPerDay: 10, // on demand syncs
//       scenarioDateRange: 10, // +10 years from signup date
//     },
//   },
//   {
//     name: "Pro+",
//     tier: Tier.ProPlus,
//     price: 1910,
//     apiId: "price_1NcmSNF93NLsr0vsCJEmP7lS",
//     period: BillingPeriod.Yearly,
//     capabilities: {
//       noOfScenarios: 50,
//       noOfSyncsPerDay: 10, // on demand syncs
//       scenarioDateRange: 10, // +10 years from signup date
//     },
//   },
// ];

// export const PLANS_TEST = [
//   {
//     name: "Demo",
//     tier: Tier.Demo,
//     price: 0,
//     capabilities: {
//       noOfScenarios: 2,
//       noOfSyncsPerDay: 4, // on demand syncs
//       scenarioDateRange: 1, // +1 years from signup date
//     },
//   },
//   {
//     name: "Starter",
//     tier: Tier.Starter,
//     price: 59,
//     apiId: "price_1NcmPGF93NLsr0vsdcRJuYgQ",
//     period: BillingPeriod.Monthly,
//     capabilities: {
//       noOfScenarios: 3,
//       noOfSyncsPerDay: 10, // on demand syncs
//       scenarioDateRange: 3, // +3 years from signup date
//     },
//   },
//   {
//     name: "Pro",
//     tier: Tier.Pro,
//     price: 99,
//     apiId: "price_1NcmPqF93NLsr0vsPqVN7Mz1",
//     period: BillingPeriod.Monthly,
//     capabilities: {
//       noOfScenarios: 10,
//       noOfSyncsPerDay: 10, // on demand syncs
//       scenarioDateRange: 10, // +10 years from signup date
//     },
//   },
//   {
//     name: "Pro+",
//     tier: Tier.ProPlus,
//     price: 199,
//     apiId: "price_1NcmQ5F93NLsr0vsjozGYOoa",
//     period: BillingPeriod.Monthly,
//     capabilities: {
//       noOfScenarios: 50,
//       noOfSyncsPerDay: 10, // on demand syncs
//       scenarioDateRange: 10, // +10 years from signup date
//     },
//   },
//   {
//     name: "Starter",
//     tier: Tier.Starter,
//     price: 570,
//     apiId: "price_1NcmRDF93NLsr0vsgaay2jJL",
//     period: BillingPeriod.Yearly,
//     capabilities: {
//       noOfScenarios: 3,
//       noOfSyncsPerDay: 10, // on demand syncs
//       scenarioDateRange: 3, // +3 years from signup date
//     },
//   },
//   {
//     name: "Pro",
//     tier: Tier.Pro,
//     price: 950,
//     apiId: "price_1NcmS7F93NLsr0vsqcSp6Dk4",
//     period: BillingPeriod.Yearly,
//     capabilities: {
//       noOfScenarios: 10,
//       noOfSyncsPerDay: 10, // on demand syncs
//       scenarioDateRange: 10, // +10 years from signup date
//     },
//   },
//   {
//     name: "Pro+",
//     tier: Tier.ProPlus,
//     price: 1910,
//     apiId: "price_1NcmSNF93NLsr0vsCJEmP7lS",
//     period: BillingPeriod.Yearly,
//     capabilities: {
//       noOfScenarios: 50,
//       noOfSyncsPerDay: 10, // on demand syncs
//       scenarioDateRange: 10, // +10 years from signup date
//     },
//   },
// ];

export const PLANS_TEST = [
  {
    name: "Demo",
    tier: Tier.Demo,
    apiId: "demo_monthly",
    period: BillingPeriod.Monthly,
    price: 0,
    capabilities: {
      noOfScenarios: 1,
      noOfSyncsPerDay: 3, // on demand syncs
      scenarioDateRange: 0.6, // +6 months from signup date
      noOfUsers: 1,
    },
  },
  {
    name: "Starter",
    tier: Tier.Starter,
    price: 10,
    apiId: "price_1NcmPGF93NLsr0vsdcRJuYgQ",
    period: BillingPeriod.Monthly,
    capabilities: {
      noOfScenarios: 6,
      noOfSyncsPerDay: 10, // on demand syncs
      scenarioDateRange: 1, // +1 years from signup date
      noOfUsers: 3,
    },
  },
  {
    name: "Pro",
    tier: Tier.Pro,
    price: 99,
    apiId: "price_1NcmPqF93NLsr0vsPqVN7Mz1",
    period: BillingPeriod.Monthly,
    capabilities: {
      noOfScenarios: 25,
      noOfSyncsPerDay: 25, // on demand syncs
      scenarioDateRange: 3, // +3 years from signup date
      noOfUsers: 10,
    },
  },
  {
    name: "Pro+",
    tier: Tier.ProPlus,
    price: 199,
    apiId: "price_1NcmQ5F93NLsr0vsjozGYOoa",
    period: BillingPeriod.Monthly,
    capabilities: {
      noOfScenarios: 50,
      noOfSyncsPerDay: 10, // on demand syncs
      scenarioDateRange: 10, // +10 years from signup date
      noOfUsers: 50,
    },
  },
  {
    name: "Demo",
    tier: Tier.Demo,
    apiId: "demo_yearly",
    period: BillingPeriod.Yearly,
    price: 0,
    capabilities: {
      noOfScenarios: 1,
      noOfSyncsPerDay: 3, // on demand syncs
      scenarioDateRange: 0.6, // +6 months from signup date
      noOfUsers: 1,
    },
  },
  {
    name: "Starter",
    tier: Tier.Starter,
    price: 570,
    apiId: "price_1NcmRDF93NLsr0vsgaay2jJL",
    period: BillingPeriod.Yearly,
    capabilities: {
      noOfScenarios: 6,
      noOfSyncsPerDay: 10, // on demand syncs
      scenarioDateRange: 1, // +1 years from signup date
      noOfUsers: 3,
    },
  },
  {
    name: "Pro",
    tier: Tier.Pro,
    price: 950,
    apiId: "price_1NcmS7F93NLsr0vsqcSp6Dk4",
    period: BillingPeriod.Yearly,
    capabilities: {
      noOfScenarios: 25,
      noOfSyncsPerDay: 25, // on demand syncs
      scenarioDateRange: 10, // +10 years from signup date
      noOfUsers: 10,
    },
  },
  {
    name: "Pro+",
    tier: Tier.ProPlus,
    price: 1910,
    apiId: "price_1NcmSNF93NLsr0vsCJEmP7lS",
    period: BillingPeriod.Yearly,
    capabilities: {
      noOfScenarios: 50,
      noOfSyncsPerDay: 10, // on demand syncs
      scenarioDateRange: 10, // +10 years from signup date
      noOfUsers: 50,
    },
  },
];

export const PLANS_PROD = [
  {
    name: "Demo",
    tier: Tier.Demo,
    price: 0,
    apiId: "demo_monthly",
    period: BillingPeriod.Monthly,
    capabilities: {
      noOfScenarios: 1,
      noOfSyncsPerDay: 3, // on demand syncs
      scenarioDateRange: 0.6, // +6 months from signup date
      noOfUsers: 1,
    },
  },
  {
    name: "Starter",
    tier: Tier.Starter,
    price: 10,
    apiId: "price_1NXIsxF93NLsr0vsrL0SvaJd",
    period: BillingPeriod.Monthly,
    capabilities: {
      noOfScenarios: 6,
      noOfSyncsPerDay: 10, // on demand syncs
      scenarioDateRange: 1, // +1 years from signup date
      noOfUsers: 3,
    },
  },
  {
    name: "Pro",
    tier: Tier.Pro,
    price: 99,
    apiId: "price_1NXKHrF93NLsr0vscL942zAV",
    period: BillingPeriod.Monthly,
    capabilities: {
      noOfScenarios: 25,
      noOfSyncsPerDay: 25, // on demand syncs
      scenarioDateRange: 3, // +3 years from signup date
      noOfUsers: 10,
    },
  },
  {
    name: "Pro+",
    tier: Tier.ProPlus,
    price: 199,
    apiId: "price_1NXKPOF93NLsr0vsgDsCJPVW",
    period: BillingPeriod.Monthly,
    capabilities: {
      noOfScenarios: 50,
      noOfSyncsPerDay: 10, // on demand syncs
      scenarioDateRange: 10, // +10 years from signup date
      noOfUsers: 50,
    },
  },
  {
    name: "Demo",
    tier: Tier.Demo,
    apiId: "demo_yearly",
    period: BillingPeriod.Yearly,
    price: 0,
    capabilities: {
      noOfScenarios: 1,
      noOfSyncsPerDay: 3, // on demand syncs
      scenarioDateRange: 0.6, // +6 months from signup date
      noOfUsers: 1,
    },
  },
  {
    name: "Starter",
    tier: Tier.Starter,
    price: 570,
    apiId: "price_1NXKGxF93NLsr0vsleK1nJuk",
    period: BillingPeriod.Yearly,
    capabilities: {
      noOfScenarios: 6,
      noOfSyncsPerDay: 10, // on demand syncs
      scenarioDateRange: 1, // +1 years from signup date
      noOfUsers: 3,
    },
  },
  {
    name: "Pro",
    tier: Tier.Pro,
    price: 950,
    apiId: "price_1NXKJUF93NLsr0vsyN0ydHQG",
    period: BillingPeriod.Yearly,
    capabilities: {
      noOfScenarios: 25,
      noOfSyncsPerDay: 25, // on demand syncs
      scenarioDateRange: 10, // +10 years from signup date
      noOfUsers: 10,
    },
  },
  {
    name: "Pro+",
    tier: Tier.ProPlus,
    price: 1910,
    apiId: "price_1NXKQoF93NLsr0vsnwi3HSx8",
    period: BillingPeriod.Yearly,
    capabilities: {
      noOfScenarios: 50,
      noOfSyncsPerDay: 10, // on demand syncs
      scenarioDateRange: 10, // +10 years from signup date
      noOfUsers: 50,
    },
  },
];

console.log(
  "process.env.REACT_APP_STRIPE_ENV",
  process.env.REACT_APP_STRIPE_ENV
);

export const PLANS =
  process.env.REACT_APP_STRIPE_ENV === "prod" ? PLANS_PROD : PLANS_TEST;

export const GLOBAL_CONSTANTS = {
  // App
  APP_NAME: "TSC Browser",
  APP_VERSION: "1.0.0",
  APP_DESCRIPTION: "TSC Browser",
  APP_AUTHOR: "The Scalable CFO",
  APP_COPYRIGHT: "Copyright © 2022 The Scalable CFO",
  APP_LOGO: "",
  APP_LOGO_URL: "",
  APP_HOMEPAGE_URL: "",

  // Others
  COOKIE_EXPIRY_TIME: new Date(new Date().getTime() + 150 * 60000),
  AVATAR_PLACEHOLDER_128x128:
    "https://bulma.io/images/placeholders/128x128.png",
  AVATAR_PLACEHOLDER_48x48: "https://bulma.io/images/placeholders/48x48.png",
  RECORD_TYPES: {
    SUPPLIER_INVOICE: "Supplier Invoice",
    CUSTOMER_INVOICE: "Customer Invoice",
    FINANCING: "Financing",
  },
};

export const TAKE = 100;

export type MomentTimeUnitBase = moment.unitOfTime.Base;

export enum CashflowRowType {
  OpeningBalance = "OpeningBalance",
  OpeningBalanceTotal = "OpeningBalanceTotal",
  RecordsGroup = "RecordsGroup",
  CashInTotal = "CashInTotal",
  CashOutTotal = "CashOutTotal",
  Record = "Record",
  NetCash = "NetCash",
  ClosingBalance = "ClosingBalance",
  Unreconciled = "Unreconciled",
}

enum PROJECT_ROUTES {
  TELL_US_WHO_YOU_ARE = "/tell-us-who-you-are",
  TELL_US_ABOUT_YOURSELF = "/tell-us-about-yourself",
  TELL_US_ABOUT_YOUR_BUSINESS = "/tell-us-about-your-business",
  WHERE_IS_IT_LOCATED = "/where-is-it-located",
}

export enum PROVIDERS {
  XERO = "Xero",
  QUICKBOOKS = "QuickBooks",
  ZOHO = "Zoho",
}
export const MONTHS_LIST = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const NEXT_STAGE: Record<string, string> = {
  // "verify-email": "what-we-do",
  // "what-we-do": "tell-us-who-you-are",
  "verify-email": "tell-us-who-you-are",
  "what-we-do": "tell-us-who-you-are",
  // "tell-us-who-you-are": "tell-us-about-yourself",
  "tell-us-who-you-are": "tell-us-about-your-business",
  "tell-us-about-yourself": "you-are-all-set-up",
  "tell-us-about-your-business": "where-is-it-located",
  "where-is-it-located": "estimated-monthly-expense",
  "estimated-monthly-expense": "you-are-all-set-up",
  "you-are-all-set-up": "",
};

export type Address = {
  country: string;
  state: string;
  street: string;
  poBox: string;
};

export type Contact = {
  types: {
    vendor: boolean;
    customer: boolean;
    employee: boolean;
  };
  name: string;
  email?: string;
  disbursementMethod: DisbursementMethod;
  beneficiary: {
    type: "person" | "organization";
    name: string;
    email: string;
    billingAddress: Address;
    bankDetails: {
      bankAddress: Address;
      bankName: string;
      currency: string;
      accountNumber: string;
      routingNumber?: string;
      sortCode?: string;
      swiftCode: string;
      iban: string;
    };
  };
};

export const INITIAL_ADDRESS: Address = {
  country: "",
  state: "",
  street: "",
  poBox: "",
};

export const INITIAL_CONTACT: Contact = {
  types: {
    vendor: true,
    customer: false,
    employee: false,
  },
  name: "",
  email: "",
  disbursementMethod: DisbursementMethod.BankTransfer,
  beneficiary: {
    type: "person",
    name: "",
    email: "",
    billingAddress: INITIAL_ADDRESS,
    bankDetails: {
      bankAddress: INITIAL_ADDRESS,
      bankName: "",
      currency: "",
      accountNumber: "",
      routingNumber: "",
      sortCode: "",
      swiftCode: "",
      iban: "",
    },
  },
};

export enum PaymentStatus {
  Unpaid = "Unpaid",
  PendingAuthorization = "PendingAuthorization",
  PendingPayment = "PendingPayment",
  PartiallyPaid = "PartiallyPaid",
  Paid = "Paid",
  Failed = "Failed",
}

export enum PeriodFilter {
  Monthly = "Monthly",
  Weekly = "Weekly",
  Daily = "Daily",
}

export type CashflowConfigs = {
  periodFilter: PeriodFilter;
  startDate: Date;
  endDate: Date;
  groupByContacts: boolean;
  filterByExpectedDueDate: boolean;
  sourceType: string;
};

export enum InvoiceTypes {
  Payable = "Payable",
  Receivable = "Receivable",
}

export enum MENU {
  BY_DATE = "by-date",
  BY_DATE_FILTER = "by-date-filter",
  BY_ISSUE_DATE = "by-issue-date",
  BY_CONTACT = "by-contact",
  BY_BUCKET = "by-cashflow-bucket",
  BY_INVOICE = "by-invoice",
  BY_TOP_CUSTOMER = "by-top-customer",
  BY_TOP_VENDOR = "by-top-vendor",
  NEWEST_TO_OLDEST = "newest-to-oldest",
  OLDEST_TO_NEWEST = "oldest-to-newest",
  THIS_WEEK = "this-week",
  THIS_MONTH = "this-month",
  THIS_QUARTER = "this-quarter",
  THIS_YEAR = "this-year",
  LAST_WEEK = "last-week",
  LAST_MONTH = "last-month",
  LAST_QUARTER = "last-quarter",
  LAST_YEAR = "last-year",
  DATE_RANGE = "date-range",
  ASCENDING = "ascending",
  DESCENDING = "descending",
}

export enum SORT_BY {
  ASC = "ASC",
  DESC = "DESC",
}
export const ReportViewTypes = [
  {
    name: "Monthly",
    value: "month" as MomentTimeUnitBase,
    prefix: "M",
    format: "MMM YYYY",
  },
  {
    name: "Weekly",
    value: "week" as MomentTimeUnitBase,
    prefix: "W",
    format: "DD-MMM-YY",
  },
  {
    name: "Daily",
    value: "day" as MomentTimeUnitBase,
    prefix: "D",
    format: "DD-MMM-YY",
  },
];
export const noCheckboxList = [
  "by-date",
  "by-date/newest-to-oldest",
  "by-date/oldest-to-newest",
  "by-issue-date",
  "by-issue-date/newest-to-oldest",
  "by-issue-date/oldest-to-newest",
  "by-contact",
  "by-contact/newest-to-oldest",
  "by-contact/oldest-to-newest",
  "by-contact/ascending",
  "by-contact/descending",
  "by-invoice",
  "by-invoice/ascending",
  "by-invoice/descending",
];

export const noCheckboxListFilter = [
  "by-date-filter",
  "by-contact",
  "by-cashflow-bucket",
  "by-top-customer",
  "by-top-vendor",
  "by-top-customer/this-week",
  "by-top-customer/this-month",
  "by-top-customer/this-quarter",
  "by-top-customer/this-year",
  "by-top-customer/last-week",
  "by-top-customer/last-month",
  "by-top-customer/last-quarter",
  "by-top-customer/last-year",
  "by-top-customer/date-range",
  "by-top-vendor/this-week",
  "by-top-vendor/this-month",
  "by-top-vendor/this-quarter",
  "by-top-vendor/this-year",
  "by-top-vendor/last-week",
  "by-top-vendor/last-month",
  "by-top-vendor/last-quarter",
  "by-top-vendor/last-year",
  "by-top-vendor/date-range",
  "by-date-filter/this-week",
  "by-date-filter/this-month",
  "by-date-filter/this-quarter",
  "by-date-filter/this-year",
  "by-date-filter/last-week",
  "by-date-filter/last-month",
  "by-date-filter/last-quarter",
  "by-date-filter/last-year",
  "by-date-filter/date-range",
];

export const MapSortFilterKeys: any = {
  "": "",
  "by-date": "By Date",
  "newest-to-oldest": "Newest to Oldest",
  "oldest-to-newest": "Oldest to Newest",
  "by-issue-date": "By Issue Date",
  "by-contact": "By Contact",
  "by-invoice": "By Invoice Code",
  "by-date-filter": "By Date",

  "this-week": "This Week",
  "this-month": "This Month",
  "this-quarter": "This Quarter",
  "this-year": "This Year",
  "last-week": "Last Week",
  "last-month": "Last Month",
  "last-quarter": "Last Quarter",
  ascending: "Ascending",
  descending: "Descending",

  "last-year": "Last Year",

  "date-range": "Date Range",
  "by-top-customer": "By Top Customer",
  "by-top-vendor": "By Top Vendor",
  "by-cashflow-bucket": "By Cashflow Category",
};
export const sortDropdownContacts = [
  {
    label: "By Contact",
    value: "by-contact",

    children: [
      {
        value: "by-contact/ascending",
        label: "Ascending",
        backendValue: "displayName",
      },
      {
        value: "by-contact/descending",
        label: "Descending",
        backendValue: "displayName",
      },
    ],
  },
];
export const filterDropdownContacts = [
  {
    label: "By Contact",
    value: "by-contact",
    children: [],
  },
  {
    label: "By Top Customer",
    value: "by-top-customer",
    children: [
      {
        value: "by-top-customer/this-week",
        label: "This Week",
      },
      {
        value: "by-top-customer/this-month",
        label: "This Month",
      },
      {
        value: "by-top-customer/this-quarter",
        label: "This Quarter",
      },
      {
        value: "by-top-customer/this-year",
        label: "This Year",
        divider: true,
      },
      {
        value: "by-top-customer/last-week",
        label: "Last Week",
      },
      {
        value: "by-top-customer/last-month",
        label: "Last Month",
      },
      {
        value: "by-top-customer/last-quarter",
        label: "Last Quarter",
      },

      {
        value: "by-top-customer/last-year",
        label: "Last Year",
        divider: true,
      },
      {
        value: "by-top-customer/date-range",
        label: "Date Range",
      },
    ],
  },
  {
    label: "By Top Vendor",
    value: "by-top-vendor",
    children: [
      {
        value: "by-top-vendor/this-week",
        label: "This Week",
      },
      {
        value: "by-top-vendor/this-month",
        label: "This Month",
      },
      {
        value: "by-top-vendor/this-quarter",
        label: "This Quarter",
      },
      {
        value: "by-top-vendor/this-year",
        label: "This Year",
        divider: true,
      },
      {
        value: "by-top-vendor/last-week",
        label: "Last Week",
      },
      {
        value: "by-top-vendor/last-month",
        label: "Last Month",
      },
      {
        value: "by-top-vendor/last-quarter",
        label: "Last Quarter",
      },

      {
        value: "by-top-vendor/last-year",
        label: "Last Year",
        divider: true,
      },
      {
        value: "by-top-vendor/date-range",
        label: "Date Range",
      },
    ],
  },
];

export const filterDropdown = [
  {
    label: "By Date",
    value: "by-date-filter",
    children: [
      {
        value: "by-date-filter/this-week",
        label: "This Week",
      },
      {
        value: "by-date-filter/this-month",
        label: "This Month",
      },
      {
        value: "by-date-filter/this-quarter",
        label: "This Quarter",
      },
      {
        value: "by-date-filter/this-year",
        label: "This Year",
        divider: true,
      },
      {
        value: "by-date-filter/last-week",
        label: "Last Week",
      },
      {
        value: "by-date-filter/last-month",
        label: "Last Month",
      },
      {
        value: "by-date-filter/last-quarter",
        label: "Last Quarter",
      },

      {
        value: "by-date-filter/last-year",
        label: "Last Year",
        divider: true,
      },
      {
        value: "by-date-filter/date-range",
        label: "Date Range",
      },
    ],
  },
  {
    label: "By Contact",
    value: "by-contact",
  },
  {
    label: "By Cashflow Category",
    value: "by-cashflow-bucket",
  },
];
export const sortDropdown = [
  {
    label: "By Date",
    value: "by-date",
    children: [
      {
        value: "by-date/newest-to-oldest",
        label: "Newest to Oldest",
        backendValue: "dueDate",
      },
      {
        value: "by-date/oldest-to-newest",
        label: "Oldest to Newest",
        backendValue: "dueDate",
      },
    ],
  },
  {
    label: "By Issue Date",
    value: "by-issue-date",

    children: [
      {
        value: "by-issue-date/newest-to-oldest",
        label: "Newest to Oldest",
        backendValue: "issueDate",
      },
      {
        value: "by-issue-date/oldest-to-newest",
        label: "Oldest to Newest",
        backendValue: "issueDate",
      },
    ],
  },
  {
    label: "By Contact",
    value: "by-contact",

    children: [
      {
        value: "by-contact/ascending",
        label: "Ascending",
        backendValue: "name",
      },
      {
        value: "by-contact/descending",
        label: "Descending",
        backendValue: "name",
      },
    ],
  },
  {
    label: "By Bill Code",
    value: "by-invoice",

    children: [
      {
        value: "by-invoice/ascending",
        label: "Ascending",
        backendValue: "invoiceNumber",
      },
      {
        value: "by-invoice/descending",
        label: "Descending",
        backendValue: "invoiceNumber",
      },
    ],
  },
];

export const sortDropdownGetPaid = [
  {
    label: "By Date",
    value: "by-date",
    children: [
      {
        value: "by-date/newest-to-oldest",
        label: "Newest to Oldest",
        backendValue: "dueDate",
      },
      {
        value: "by-date/oldest-to-newest",
        label: "Oldest to Newest",
        backendValue: "dueDate",
      },
    ],
  },
  {
    label: "By Issue Date",
    value: "by-issue-date",

    children: [
      {
        value: "by-issue-date/newest-to-oldest",
        label: "Newest to Oldest",
        backendValue: "issueDate",
      },
      {
        value: "by-issue-date/oldest-to-newest",
        label: "Oldest to Newest",
        backendValue: "issueDate",
      },
    ],
  },
  {
    label: "By Contact",
    value: "by-contact",

    children: [
      {
        value: "by-contact/ascending",
        label: "Ascending",
        backendValue: "name",
      },
      {
        value: "by-contact/descending",
        label: "Descending",
        backendValue: "name",
      },
    ],
  },

  {
    label: "By Invoice Code",
    value: "by-invoice",

    children: [
      {
        value: "by-invoice/ascending",
        label: "Ascending",
        backendValue: "invoiceNumber",
      },
      {
        value: "by-invoice/descending",
        label: "Descending",
        backendValue: "invoiceNumber",
      },
    ],
  },
];

export const sortDropdownEstimates = [
  {
    label: "By Current Estimate Date",
    value: "by-date",
    children: [
      {
        value: "by-date/newest-to-oldest",
        label: "Newest to Oldest",
        backendValue: "dueDate",
      },
      {
        value: "by-date/oldest-to-newest",
        label: "Oldest to Newest",
        backendValue: "dueDate",
      },
    ],
  },

  {
    label: "By Contact",
    value: "by-contact",

    children: [
      {
        value: "by-contact/ascending",
        label: "Ascending",
        backendValue: "name",
      },
      {
        value: "by-contact/descending",
        label: "Descending",
        backendValue: "name",
      },
    ],
  },
];
export const BASE_CURRENCY = "AED";

export const PREV_STAGE: Record<string, string> = {
  [PROJECT_ROUTES.TELL_US_ABOUT_YOURSELF]: PROJECT_ROUTES.TELL_US_WHO_YOU_ARE,
  [PROJECT_ROUTES.TELL_US_ABOUT_YOUR_BUSINESS]:
    PROJECT_ROUTES.TELL_US_ABOUT_YOURSELF,
  [PROJECT_ROUTES.WHERE_IS_IT_LOCATED]:
    PROJECT_ROUTES.TELL_US_ABOUT_YOUR_BUSINESS,
};

export const SIGN_UP_ROUTES = [
  "/verify-email",
  "/what-we-do",
  "/tell-us-who-you-are",
  "/tell-us-about-yourself",
  "/tell-us-about-your-business",
  "/where-is-it-located",
  "/estimated-monthly-expense",
  "/you-are-all-set-up",
  "/login",
  "/signup",
  "/forget-password",
  "/change-password",
];

export const COUNTRIES_LIST: any = [
  { label: "Afghanistan", value: "Afghanistan", code: "AF" },
  { label: "Åland Islands", value: "Aland Islands", code: "AX" },
  { label: "Albania", value: "Albania", code: "AL" },
  { label: "Algeria", value: "Algeria", code: "DZ" },
  { label: "American Samoa", value: "American Samoa", code: "AS" },
  { label: "AndorrA", value: "AndorrA", code: "AD" },
  { label: "Angola", value: "Angola", code: "AO" },
  { label: "Anguilla", value: "Anguilla", code: "AI" },
  { label: "Antarctica", value: "Antarctica", code: "AQ" },
  { label: "Antigua and Barbuda", value: "Antigua and Barbuda", code: "AG" },
  { label: "Argentina", value: "Argentina", code: "AR" },
  { label: "Armenia", value: "Armenia", code: "AM" },
  { label: "Aruba", value: "Aruba", code: "AW" },
  { label: "Australia", value: "Australia", code: "AU" },
  { label: "Austria", value: "Austria", code: "AT" },
  { label: "Azerbaijan", value: "Azerbaijan", code: "AZ" },
  { label: "Bahamas", value: "Bahamas", code: "BS" },
  { label: "Bahrain", value: "Bahrain", code: "BH" },
  { label: "Bangladesh", value: "Bangladesh", code: "BD" },
  { label: "Barbados", value: "Barbados", code: "BB" },
  { label: "Belarus", value: "Belarus", code: "BY" },
  { label: "Belgium", value: "Belgium", code: "BE" },
  { label: "Belize", value: "Belize", code: "BZ" },
  { label: "Benin", value: "Benin", code: "BJ" },
  { label: "Bermuda", value: "Bermuda", code: "BM" },
  { label: "Bhutan", value: "Bhutan", code: "BT" },
  { label: "Bolivia", value: "Bolivia", code: "BO" },
  {
    label: "Bosnia and Herzegovina",
    value: "Bosnia and Herzegovina",
    code: "BA",
  },
  { label: "Botswana", value: "Botswana", code: "BW" },
  { label: "Bouvet Island", value: "Bouvet Island", code: "BV" },
  { label: "Brazil", value: "Brazil", code: "BR" },
  {
    label: "British Indian Ocean Territory",
    value: "British Indian Ocean Territory",
    code: "IO",
  },
  { label: "Brunei Darussalam", value: "Brunei Darussalam", code: "BN" },
  { label: "Bulgaria", value: "Bulgaria", code: "BG" },
  { label: "Burkina Faso", value: "Burkina Faso", code: "BF" },
  { label: "Burundi", value: "Burundi", code: "BI" },
  { label: "Cambodia", value: "Cambodia", code: "KH" },
  { label: "Cameroon", value: "Cameroon", code: "CM" },
  { label: "Canada", value: "Canada", code: "CA" },
  { label: "Cape Verde", value: "Cape Verde", code: "CV" },
  { label: "Cayman Islands", value: "Cayman Islands", code: "KY" },
  {
    label: "Central African Republic",
    value: "Central African Republic",
    code: "CF",
  },
  { label: "Chad", value: "Chad", code: "TD" },
  { label: "Chile", value: "Chile", code: "CL" },
  { label: "China", value: "China", code: "CN" },
  { label: "Christmas Island", value: "Christmas Island", code: "CX" },
  {
    label: "Cocos (Keeling) Islands",
    value: "Cocos (Keeling) Islands",
    code: "CC",
  },
  { label: "Colombia", value: "Colombia", code: "CO" },
  { label: "Comoros", value: "Comoros", code: "KM" },
  { label: "Congo", value: "Congo", code: "CG" },
  {
    label: "Congo, The Democratic Republic of the",
    value: "Congo, The Democratic Republic of the",
    code: "CD",
  },
  { label: "Cook Islands", value: "Cook Islands", code: "CK" },
  { label: "Costa Rica", value: "Costa Rica", code: "CR" },
  { label: "Cote D'Ivoire", value: "Cote D'Ivoire", code: "CI" },
  { label: "Croatia", value: "Croatia", code: "HR" },
  { label: "Cuba", value: "Cuba", code: "CU" },
  { label: "Cyprus", value: "Cyprus", code: "CY" },
  { label: "Czech Republic", value: "Czech Republic", code: "CZ" },
  { label: "Denmark", value: "Denmark", code: "DK" },
  { label: "Djibouti", value: "Djibouti", code: "DJ" },
  { label: "Dominica", value: "Dominica", code: "DM" },
  { label: "Dominican Republic", value: "Dominican Republic", code: "DO" },
  { label: "Ecuador", value: "Ecuador", code: "EC" },
  { label: "Egypt", value: "Egypt", code: "EG" },
  { label: "El Salvador", value: "El Salvador", code: "SV" },
  { label: "Equatorial Guinea", value: "Equatorial Guinea", code: "GQ" },
  { label: "Eritrea", value: "Eritrea", code: "ER" },
  { label: "Estonia", value: "Estonia", code: "EE" },
  { label: "Ethiopia", value: "Ethiopia", code: "ET" },
  {
    label: "Falkland Islands (Malvinas)",
    value: "Falkland Islands (Malvinas)",
    code: "FK",
  },
  { label: "Faroe Islands", value: "Faroe Islands", code: "FO" },
  { label: "Fiji", value: "Fiji", code: "FJ" },
  { label: "Finland", value: "Finland", code: "FI" },
  { label: "France", value: "France", code: "FR" },
  { label: "French Guiana", value: "French Guiana", code: "GF" },
  { label: "French Polynesia", value: "French Polynesia", code: "PF" },
  {
    label: "French Southern Territories",
    value: "French Southern Territories",
    code: "TF",
  },
  { label: "Gabon", value: "Gabon", code: "GA" },
  { label: "Gambia", value: "Gambia", code: "GM" },
  { label: "Georgia", value: "Georgia", code: "GE" },
  { label: "Germany", value: "Germany", code: "DE" },
  { label: "Ghana", value: "Ghana", code: "GH" },
  { label: "Gibraltar", value: "Gibraltar", code: "GI" },
  { label: "Greece", value: "Greece", code: "GR" },
  { label: "Greenland", value: "Greenland", code: "GL" },
  { label: "Grenada", value: "Grenada", code: "GD" },
  { label: "Guadeloupe", value: "Guadeloupe", code: "GP" },
  { label: "Guam", value: "Guam", code: "GU" },
  { label: "Guatemala", value: "Guatemala", code: "GT" },
  { label: "Guernsey", value: "Guernsey", code: "GG" },
  { label: "Guinea", value: "Guinea", code: "GN" },
  { label: "Guinea-Bissau", value: "Guinea-Bissau", code: "GW" },
  { label: "Guyana", value: "Guyana", code: "GY" },
  { label: "Haiti", value: "Haiti", code: "HT" },
  {
    label: "Heard Island and Mcdonald Islands",
    value: "Heard Island and Mcdonald Islands",
    code: "HM",
  },
  {
    label: "Holy See (Vatican City State)",
    value: "Holy See (Vatican City State)",
    code: "VA",
  },
  { label: "Honduras", value: "Honduras", code: "HN" },
  { label: "Hong Kong", value: "Hong Kong", code: "HK" },
  { label: "Hungary", value: "Hungary", code: "HU" },
  { label: "Iceland", value: "Iceland", code: "IS" },
  { label: "India", value: "India", code: "IN" },
  { label: "Indonesia", value: "Indonesia", code: "ID" },
  {
    label: "Iran, Islamic Republic Of",
    value: "Iran, Islamic Republic Of",
    code: "IR",
  },
  { label: "Iraq", value: "Iraq", code: "IQ" },
  { label: "Ireland", value: "Ireland", code: "IE" },
  { label: "Isle of Man", value: "Isle of Man", code: "IM" },
  { label: "Israel", value: "Israel", code: "IL" },
  { label: "Italy", value: "Italy", code: "IT" },
  { label: "Jamaica", value: "Jamaica", code: "JM" },
  { label: "Japan", value: "Japan", code: "JP" },
  { label: "Jersey", value: "Jersey", code: "JE" },
  { label: "Jordan", value: "Jordan", code: "JO" },
  { label: "Kazakhstan", value: "Kazakhstan", code: "KZ" },
  { label: "Kenya", value: "Kenya", code: "KE" },
  { label: "Kiribati", value: "Kiribati", code: "KI" },
  {
    label: "Korea, Democratic People'S Republic of",
    value: "Korea, Democratic People'S Republic of",
    code: "KP",
  },
  { label: "Korea, Republic of", value: "Korea, Republic of", code: "KR" },
  { label: "Kuwait", value: "Kuwait", code: "KW" },
  { label: "Kyrgyzstan", value: "Kyrgyzstan", code: "KG" },
  {
    label: "Lao People'S Democratic Republic",
    value: "Lao People'S Democratic Republic",
    code: "LA",
  },
  { label: "Latvia", value: "Latvia", code: "LV" },
  { label: "Lebanon", value: "Lebanon", code: "LB" },
  { label: "Lesotho", value: "Lesotho", code: "LS" },
  { label: "Liberia", value: "Liberia", code: "LR" },
  {
    label: "Libyan Arab Jamahiriya",
    value: "Libyan Arab Jamahiriya",
    code: "LY",
  },
  { label: "Liechtenstein", value: "Liechtenstein", code: "LI" },
  { label: "Lithuania", value: "Lithuania", code: "LT" },
  { label: "Luxembourg", value: "Luxembourg", code: "LU" },
  { label: "Macao", value: "Macao", code: "MO" },
  {
    label: "Macedonia, The Former Yugoslav Republic of",
    value: "Macedonia, The Former Yugoslav Republic of",
    code: "MK",
  },
  { label: "Madagascar", value: "Madagascar", code: "MG" },
  { label: "Malawi", value: "Malawi", code: "MW" },
  { label: "Malaysia", value: "Malaysia", code: "MY" },
  { label: "Maldives", value: "Maldives", code: "MV" },
  { label: "Mali", value: "Mali", code: "ML" },
  { label: "Malta", value: "Malta", code: "MT" },
  { label: "Marshall Islands", value: "Marshall Islands", code: "MH" },
  { label: "Martinique", value: "Martinique", code: "MQ" },
  { label: "Mauritania", value: "Mauritania", code: "MR" },
  { label: "Mauritius", value: "Mauritius", code: "MU" },
  { label: "Mayotte", value: "Mayotte", code: "YT" },
  { label: "Mexico", value: "Mexico", code: "MX" },
  {
    label: "Micronesia, Federated States of",
    value: "Micronesia, Federated States of",
    code: "FM",
  },
  { label: "Moldova, Republic of", value: "Moldova, Republic of", code: "MD" },
  { label: "Monaco", value: "Monaco", code: "MC" },
  { label: "Mongolia", value: "Mongolia", code: "MN" },
  { label: "Montserrat", value: "Montserrat", code: "MS" },
  { label: "Morocco", value: "Morocco", code: "MA" },
  { label: "Mozambique", value: "Mozambique", code: "MZ" },
  { label: "Myanmar", value: "Myanmar", code: "MM" },
  { label: "Namibia", value: "Namibia", code: "NA" },
  { label: "Nauru", value: "Nauru", code: "NR" },
  { label: "Nepal", value: "Nepal", code: "NP" },
  { label: "Netherlands", value: "Netherlands", code: "NL" },
  { label: "Netherlands Antilles", value: "Netherlands Antilles", code: "AN" },
  { label: "New Caledonia", value: "New Caledonia", code: "NC" },
  { label: "New Zealand", value: "New Zealand", code: "NZ" },
  { label: "Nicaragua", value: "Nicaragua", code: "NI" },
  { label: "Niger", value: "Niger", code: "NE" },
  { label: "Nigeria", value: "Nigeria", code: "NG" },
  { label: "Niue", value: "Niue", code: "NU" },
  { label: "Norfolk Island", value: "Norfolk Island", code: "NF" },
  {
    label: "Northern Mariana Islands",
    value: "Northern Mariana Islands",
    code: "MP",
  },
  { label: "Norway", value: "Norway", code: "NO" },
  { label: "Oman", value: "Oman", code: "OM" },
  { label: "Pakistan", value: "Pakistan", code: "PK" },
  { label: "Palau", value: "Palau", code: "PW" },
  {
    label: "Palestinian Territory, Occupied",
    value: "Palestinian Territory, Occupied",
    code: "PS",
  },
  { label: "Panama", value: "Panama", code: "PA" },
  { label: "Papua New Guinea", value: "Papua New Guinea", code: "PG" },
  { label: "Paraguay", value: "Paraguay", code: "PY" },
  { label: "Peru", value: "Peru", code: "PE" },
  { label: "Philippines", value: "Philippines", code: "PH" },
  { label: "Pitcairn", value: "Pitcairn", code: "PN" },
  { label: "Poland", value: "Poland", code: "PL" },
  { label: "Portugal", value: "Portugal", code: "PT" },
  { label: "Puerto Rico", value: "Puerto Rico", code: "PR" },
  { label: "Qatar", value: "Qatar", code: "QA" },
  { label: "Reunion", value: "Reunion", code: "RE" },
  { label: "Romania", value: "Romania", code: "RO" },
  { label: "Russian Federation", value: "Russian Federation", code: "RU" },
  { label: "RWANDA", value: "RWANDA", code: "RW" },
  { label: "Saint Helena", value: "Saint Helena", code: "SH" },
  {
    label: "Saint Kitts and Nevis",
    value: "Saint Kitts and Nevis",
    code: "KN",
  },
  { label: "Saint Lucia", value: "Saint Lucia", code: "LC" },
  {
    label: "Saint Pierre and Miquelon",
    value: "Saint Pierre and Miquelon",
    code: "PM",
  },
  {
    label: "Saint Vincent and the Grenadines",
    value: "Saint Vincent and the Grenadines",
    code: "VC",
  },
  { label: "Samoa", value: "Samoa", code: "WS" },
  { label: "San Marino", value: "San Marino", code: "SM" },
  {
    label: "Sao Tome and Principe",
    value: "Sao Tome and Principe",
    code: "ST",
  },
  { label: "Saudi Arabia", value: "Saudi Arabia", code: "SA" },
  { label: "Senegal", value: "Senegal", code: "SN" },
  {
    label: "Serbia and Montenegro",
    value: "Serbia and Montenegro",
    code: "CS",
  },
  { label: "Seychelles", value: "Seychelles", code: "SC" },
  { label: "Sierra Leone", value: "Sierra Leone", code: "SL" },
  { label: "Singapore", value: "Singapore", code: "SG" },
  { label: "Slovakia", value: "Slovakia", code: "SK" },
  { label: "Slovenia", value: "Slovenia", code: "SI" },
  { label: "Solomon Islands", value: "Solomon Islands", code: "SB" },
  { label: "Somalia", value: "Somalia", code: "SO" },
  { label: "South Africa", value: "South Africa", code: "ZA" },
  {
    label: "South Georgia and the South Sandwich Islands",
    value: "South Georgia and the South Sandwich Islands",
    code: "GS",
  },
  { label: "Spain", value: "Spain", code: "ES" },
  { label: "Sri Lanka", value: "Sri Lanka", code: "LK" },
  { label: "Sudan", value: "Sudan", code: "SD" },
  { label: "Surilabel", value: "Surilabel", code: "SR" },
  {
    label: "Svalbard and Jan Mayen",
    value: "Svalbard and Jan Mayen",
    code: "SJ",
  },
  { label: "Swaziland", value: "Swaziland", code: "SZ" },
  { label: "Sweden", value: "Sweden", code: "SE" },
  { label: "Switzerland", value: "Switzerland", code: "CH" },
  { label: "Syrian Arab Republic", value: "Syrian Arab Republic", code: "SY" },
  {
    label: "Taiwan, Province of China",
    value: "Taiwan, Province of China",
    code: "TW",
  },
  { label: "Tajikistan", value: "Tajikistan", code: "TJ" },
  {
    label: "Tanzania, United Republic of",
    value: "Tanzania, United Republic of",
    code: "TZ",
  },
  { label: "Thailand", value: "Thailand", code: "TH" },
  { label: "Timor-Leste", value: "Timor-Leste", code: "TL" },
  { label: "Togo", value: "Togo", code: "TG" },
  { label: "Tokelau", value: "Tokelau", code: "TK" },
  { label: "Tonga", value: "Tonga", code: "TO" },
  { label: "Trinidad and Tobago", value: "Trinidad and Tobago", code: "TT" },
  { label: "Tunisia", value: "Tunisia", code: "TN" },
  { label: "Turkey", value: "Turkey", code: "TR" },
  { label: "Turkmenistan", value: "Turkmenistan", code: "TM" },
  {
    label: "Turks and Caicos Islands",
    value: "Turks and Caicos Islands",
    code: "TC",
  },
  { label: "Tuvalu", value: "Tuvalu", code: "TV" },
  { label: "Uganda", value: "Uganda", code: "UG" },
  { label: "Ukraine", value: "Ukraine", code: "UA" },
  { label: "United Arab Emirates", value: "United Arab Emirates", code: "AE" },
  { label: "United Kingdom", value: "United Kingdom", code: "GB" },
  { label: "United States", value: "United States", code: "US" },
  {
    label: "United States Minor Outlying Islands",
    value: "United States Minor Outlying Islands",
    code: "UM",
  },
  { label: "Uruguay", value: "Uruguay", code: "UY" },
  { label: "Uzbekistan", value: "Uzbekistan", code: "UZ" },
  { label: "Vanuatu", value: "Vanuatu", code: "VU" },
  { label: "Venezuela", value: "Venezuela", code: "VE" },
  { label: "Viet Nam", value: "Viet Nam", code: "VN" },
  {
    label: "Virgin Islands, British",
    value: "Virgin Islands, British",
    code: "VG",
  },
  { label: "Virgin Islands, U.S.", value: "Virgin Islands, U.S.", code: "VI" },
  { label: "Wallis and Futuna", value: "Wallis and Futuna", code: "WF" },
  { label: "Western Sahara", value: "Western Sahara", code: "EH" },
  { label: "Yemen", value: "Yemen", code: "YE" },
  { label: "Zambia", value: "Zambia", code: "ZM" },
  { label: "Zimbabwe", value: "Zimbabwe", code: "ZW" },
];

export const INDUSTRIES_LIST: any = [
  { id: 2, label: "Accounting", value: "Accounting" },
  { id: 3, label: "Airline", value: "Airline" },
  {
    id: 4,
    label: "Alternative Dispute Resolution",
    value: "Alternative Dispute Resolution",
  },
  {
    id: 5,
    label: "Alternative Medicine",
    value: "Alternative Medicine",
  },
  { id: 6, label: "Animation", value: "Animation" },
  { id: 7, label: "Apparel & Fashion", value: "Apparel & Fashion" },
  {
    id: 8,
    label: "Architecture And Planning",
    value: "Architecture And Planning",
  },
  { id: 9, label: "Arts And Crafts", value: "Arts And Crafts" },
  { id: 10, label: "Automotive", value: "Automotive" },
  {
    id: 11,
    label: "Aviation And Aerospace",
    value: "Aviation And Aerospace",
  },
  { id: 12, label: "Banking", value: "Banking" },
  { id: 13, label: "Biotechnology", value: "Biotechnology" },
  { id: 14, label: "Broadcast Media", value: "Broadcast Media" },
  { id: 15, label: "Building Materials", value: "Building Materials" },
  {
    id: 16,
    label: "Business Supplies And Equipment",
    value: "Business Supplies And Equipment",
  },
  { id: 17, label: "Capital Markets", value: "Capital Markets" },
  { id: 18, label: "Chemicals", value: "Chemicals" },
  {
    id: 19,
    label: "Civic And Social Organization",
    value: "Civic And Social Organization",
  },
  { id: 20, label: "Civil Engineering", value: "Civil Engineering" },
  {
    id: 21,
    label: "Commercial Real Estate",
    value: "Commercial Real Estate",
  },
  {
    id: 22,
    label: "Computer And Network Security",
    value: "Computer And Network Security",
  },
  { id: 23, label: "Computer Games", value: "Computer Games" },
  { id: 24, label: "Computer Hardware", value: "Computer Hardware" },
  { id: 25, label: "Computer Software", value: "Computer Software" },
  { id: 26, label: "Construction", value: "Construction" },
  {
    id: 27,
    label: "Consumer Electronics",
    value: "Consumer Electronics",
  },
  { id: 28, label: "Consumer Goods", value: "Consumer Goods" },
  { id: 29, label: "Consumer Service", value: "Consumer Service" },
  { id: 30, label: "Cosmetics", value: "Cosmetics" },
  { id: 31, label: "Dairy", value: "Dairy" },
  { id: 32, label: "Defense And Space", value: "Defense And Space" },
  { id: 33, label: "Design", value: "Design" },
  { id: 34, label: "Education", value: "Education" },
  { id: 35, label: "E-Learnings", value: "E-Learnings" },
  {
    id: 36,
    label: "Electrics Manufacturing",
    value: "Electrics Manufacturing",
  },
  { id: 37, label: "Engineering", value: "Engineering" },
  { id: 38, label: "Entertainment", value: "Entertainment" },
  { id: 39, label: "Environment", value: "Environment" },
  { id: 40, label: "Events Services", value: "Events Services" },
  { id: 41, label: "Executive Office", value: "Executive Office" },
  { id: 42, label: "Executive Services", value: "Executive Services" },
  {
    id: 43,
    label: "Facilities Services",
    value: "Facilities Services",
  },
  { id: 44, label: "Farming", value: "Farming" },
  { id: 45, label: "Financial Services", value: "Financial Services" },
  { id: 46, label: "Fine Art", value: "Fine Art" },
  { id: 47, label: "Fishery", value: "Fishery" },
  { id: 48, label: "Food And Beverages", value: "Food And Beverages" },
  { id: 49, label: "Food Production", value: "Food Production" },
  { id: 50, label: "Fund Raising", value: "Fund Raising" },
  { id: 51, label: "Furniture", value: "Furniture" },
  { id: 52, label: "Glass and Ceramics", value: "Glass and Ceramics" },
  {
    id: 53,
    label: "Government Administration",
    value: "Government Administration",
  },
  { id: 54, label: "Graphics Design", value: "Graphics Design" },
  {
    id: 55,
    label: "Health And Wellness And Fitness",
    value: "Health And Wellness And Fitness",
  },
  { id: 56, label: "Higher Education", value: "Higher Education" },
  {
    id: 57,
    label: "Hospital And Healthcare",
    value: "Hospital And Healthcare",
  },
  { id: 58, label: "Hospitality", value: "Hospitality" },
  { id: 59, label: "Human Resources", value: "Human Resources" },
  { id: 60, label: "Import And Export", value: "Import And Export" },
  {
    id: 61,
    label: "Individual And Family Services",
    value: "Individual And Family Services",
  },
  {
    id: 62,
    label: "Industrial Automation",
    value: "Industrial Automation",
  },
  {
    id: 63,
    label: "Information Services",
    value: "Information Services",
  },
  {
    id: 64,
    label: "Information Technology Services",
    value: "Information Technology Services",
  },
  { id: 65, label: "Insurance", value: "Insurance" },
  {
    id: 66,
    label: "International Affaire",
    value: "International Affaire",
  },
  {
    id: 67,
    label: "International Trade And Development",
    value: "International Trade And Development",
  },
  { id: 68, label: "Internet", value: "Internet" },
  { id: 69, label: "Investment Banking", value: "Investment Banking" },
  {
    id: 70,
    label: "Investment Management",
    value: "Investment Management",
  },
  { id: 71, label: "Judiciary", value: "Judiciary" },
  { id: 72, label: "Law Enforcement", value: "Law Enforcement" },
  { id: 73, label: "Law Practice", value: "Law Practice" },
  { id: 74, label: "Legal Service", value: "Legal Service" },
  { id: 75, label: "Legislative Office", value: "Legislative Office" },
  {
    id: 76,
    label: "Leisure Travel And Tourism",
    value: "Leisure Travel And Tourism",
  },
  { id: 77, label: "Libraries", value: "Libraries" },
  {
    id: 78,
    label: "Logistics And Supply Chain",
    value: "Logistics And Supply Chain",
  },
  { id: 79, label: "Luxury Goods", value: "Luxury Goods" },
  { id: 80, label: "Machinery", value: "Machinery" },
  {
    id: 81,
    label: "Management Consulting",
    value: "Management Consulting",
  },
  { id: 82, label: "Maritime", value: "Maritime" },
  { id: 83, label: "Market Research", value: "Market Research" },
  {
    id: 84,
    label: "Marketing And Advertising",
    value: "Marketing And Advertising",
  },
  {
    id: 85,
    label: "Mechanical And Industrial",
    value: "Mechanical And Industrial",
  },
  { id: 86, label: "Media Production", value: "Media Production" },
  { id: 87, label: "Medical Devices", value: "Medical Devices" },
  { id: 88, label: "Medical Practices", value: "Medical Practices" },
  { id: 89, label: "Mental Healthcare", value: "Mental Healthcare" },
  { id: 90, label: "Military", value: "Military" },
  { id: 91, label: "Mining And Metals", value: "Mining And Metals" },
  {
    id: 92,
    label: "Motion Picture And Film",
    value: "Motion Picture And Film",
  },
  {
    id: 93,
    label: "Museums And Institutions",
    value: "Museums And Institutions",
  },
  { id: 94, label: "Music", value: "Music" },
  { id: 95, label: "Nano Technology", value: "Nano Technology" },
  { id: 96, label: "Newspapers", value: "Newspapers" },
  {
    id: 97,
    label: "Non Profit Organization Management",
    value: "Non Profit Organization Management",
  },
  { id: 98, label: "Oil And Energy", value: "Oil And Energy" },
  { id: 99, label: "On-Line Media", value: "On-Line Media" },
  {
    id: 100,
    label: "Outsourcing Off Shoring",
    value: "Outsourcing Off Shoring",
  },
  {
    id: 101,
    label: "Package Freight Delivery",
    value: "Package Freight Delivery",
  },
  {
    id: 102,
    label: "Packaging And Containers",
    value: "Packaging And Containers",
  },
  {
    id: 103,
    label: "Paper And Forest Products",
    value: "Paper And Forest Products",
  },
  { id: 104, label: "Performing Arts", value: "Performing Arts" },
  { id: 105, label: "Pharmaceuticals", value: "Pharmaceuticals" },
  { id: 106, label: "Philanthropy", value: "Philanthropy" },
  { id: 107, label: "Photography", value: "Photography" },
  { id: 108, label: "Plastics", value: "Plastics" },
  {
    id: 109,
    label: "Political Organizations",
    value: "Political Organizations",
  },
  {
    id: 110,
    label: "Primary Secondary Education",
    value: "Primary Secondary Education",
  },
  { id: 111, label: "Printing", value: "Printing" },
  {
    id: 112,
    label: "Professional Services",
    value: "Professional Services",
  },
  {
    id: 113,
    label: "Professional Training And Coaching",
    value: "Professional Training And Coaching",
  },
  {
    id: 114,
    label: "Program Development",
    value: "Program Development",
  },
  { id: 115, label: "Public Policy", value: "Public Policy" },
  {
    id: 116,
    label: "Public Relations And Communications",
    value: "Public Relations And Communications",
  },
  { id: 117, label: "Public Safety", value: "Public Safety" },
  { id: 118, label: "Publishing", value: "Publishing" },
  {
    id: 119,
    label: "Railroad Manufacture",
    value: "Railroad Manufacture",
  },
  { id: 120, label: "Ranching", value: "Ranching" },
  { id: 121, label: "Real Estate", value: "Real Estate" },
  {
    id: 122,
    label: "Recreational Facilities And Services",
    value: "Recreational Facilities And Services",
  },
  {
    id: 123,
    label: "Religious Institutions",
    value: "Religious Institutions",
  },
  {
    id: 124,
    label: "Renewable And Environment",
    value: "Renewable And Environment",
  },
  { id: 125, label: "Research", value: "Research" },
  { id: 126, label: "Restraints", value: "Restraints" },
  { id: 127, label: "Retail", value: "Retail" },
  {
    id: 128,
    label: "Security And Investigations",
    value: "Security And Investigations",
  },
  { id: 129, label: "Semiconductors", value: "Semiconductors" },
  { id: 130, label: "Services", value: "Services" },
  { id: 131, label: "Shipbuilding", value: "Shipbuilding" },
  { id: 132, label: "Sporting Goods", value: "Sporting Goods" },
  { id: 133, label: "Sports", value: "Sports" },
  { id: 134, label: "Staffing", value: "Staffing" },
  { id: 135, label: "Supermarkets", value: "Supermarkets" },
  { id: 136, label: "Telecommunications", value: "Telecommunications" },
  { id: 137, label: "Textiles", value: "Textiles" },
  { id: 138, label: "Think Tanks", value: "Think Tanks" },
  { id: 139, label: "Tobacco", value: "Tobacco" },
  {
    id: 140,
    label: "Translation And Localization",
    value: "Translation And Localization",
  },
  {
    id: 141,
    label: "Transportation / Trucking / Railroad",
    value: "Transportation / Trucking / Railroad",
  },
  { id: 142, label: "Utilities", value: "Utilities" },
  {
    id: 143,
    label: "Venture Capital And Private Equity",
    value: "Venture Capital And Private Equity",
  },
  { id: 144, label: "Veterinary", value: "Veterinary" },
  { id: 145, label: "Warehousing", value: "Warehousing" },
  { id: 146, label: "Wholesale", value: "Wholesale" },
  { id: 147, label: "Wine And Spirits", value: "Wine And Spirits" },
  { id: 148, label: "Wireless", value: "Wireless" },
  {
    id: 149,
    label: "Writing And Editing",
    value: "Writing And Editing",
  },
];

export enum SourceType {
  Bank = "Bank",
  AccountingSoftware = "AccountingSoftware",
  Manual = "Manual",
}

export const FREQUENCY_FILTERS = [
  {
    id: 1,
    label: "Monthly",
    value: "monthly",
  },
  {
    id: 2,
    label: "Weekly",
    value: "weekly",
  },
  {
    id: 3,
    label: "Daily",
    value: "daily",
  },
];
